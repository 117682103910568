import { equipments as spotmarketEquipments } from "src/models/spotmarketEquipments";
import { status as spotmarketStatus } from "src/models/spotmarketStatus";

const internalStatuses = ['new_load', 'need_post', 'updated_rate', 'updated_load', 'for_deletion'];

export function getSpotmarketStatus(shipment_status, filter = 'all') {
    if (!shipment_status || shipment_status.length === 0) return [];

    const status = shipment_status.filter((item) => {
        if (filter === 'regular') 
            return !internalStatuses.includes(item);
        else if (filter === 'internal')
            return internalStatuses.includes(item);
        return true;
    });

    if (Array.isArray(status)) {
        const result = status.map(
            (status) => spotmarketStatus.find((item) => item.key === status))
            .filter(Boolean);

        return result;
    }

    return [];
}

export function getSpotmarketStatusColumns(shipment_status, filter = "all") {
    if (!shipment_status) return []
    return getSpotmarketStatus(shipment_status, filter);
}

export function getSpotmarketStatusDetails(shipment_status, filter = 'all') {
    if (!shipment_status) return '--';
    const status = getSpotmarketStatus(shipment_status, filter);
    return status.map((item) => item.label).join(', ');
}

export function getSpotmarketEquipments(equipments) {
    if (!equipments || equipments.length === 0) return [];
    return equipments.map((key) => {
        const foundEquipment = spotmarketEquipments.find((item) => item.key === key);
        return foundEquipment ? foundEquipment.label : key;
    });
}
