import { useMutation } from "react-query";
import { ShipmentConverterService } from "src/api/shipmentConverter.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Shipment } from "src/models/shipment";
import { FreightClassUtils } from "src/utils/freight";

export function useShipmentConverterQueries() {
  const { session } = useAuth();
  const { setSnackbarMessage } = useSnackbar();
  const service = new ShipmentConverterService(session.token, session.tenant._id);

  const {
    isLoading: isConverting,
    mutate: convertBOLToShipment,
    data: shipment,
  } = useMutation({
    mutationFn: async ({ file }) => {
      const bolShipment = await service.bol2Shipment(file);
      const shipment = Shipment.fromJson(bolShipment, 'fromBOL');
      shipment.items.forEach((item) => {
        if (!item.class) {
          item.class = FreightClassUtils.calc(
            item.actualWeight,
            item.actualWeightUOM,
            item.actualLength,
            item.actualWidth,
            item.actualHeight,
            item.actualDimensionsUOM,
            item.actualQty
          );
        }
      });

      return shipment; 
    },
    onError: (error) => {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    },
  });

  return {
    isConverting,
    convertBOLToShipment,
    shipment
  };
}
