import {
  Check,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  ControlPointDuplicate,
  DoDisturb,
  Edit,
  Visibility,
} from "@mui/icons-material";

import { Box, Button, Link, Tooltip } from "@mui/material";
import { shipmentServices } from "./services";
import { ShipmentUtils } from "src/utils";

import { getGridDateOperators } from '@mui/x-data-grid';
import { customNumericOperators } from "./customFilterOperators";

const dayInMonthComparator = (v1, v2) => {
  if (!v1 || !v2) return 1;
  const dateV1 = new Date(v1);
  const dateV2 = new Date(v2);

  if (isNaN(dateV1) || isNaN(dateV1)) return 1;

  return dateV1.getTime() - dateV2.getTime();
};

export const dailySummaryColumns = (
  session,
  tenant,
  invalidShipments = false,
  onApproveItem,
  onDenyItem
) => {

  const poNumberLabel = ShipmentUtils.getPONumberLabel(tenant?._id);

  const cols = [
    {
      field: "poNumber",
      headerName: poNumberLabel,
      minWidth: 100,
    },
    {
      field: "primaryReference",
      headerName: "Primary Reference",
      minWidth: 120,
      valueGetter: (params) => {
        // Merge original primary references for combined shipments for users be able to search for.
        const shipment = params.row;
        const result = `${shipment.primaryReference}, ${shipment.combinedFrom
          ?.map((combined) => combined.primaryReference)
          .join(", ") ?? ""
          }`;
        return result;
      },
      renderCell: (params) => <div>{params.row.primaryReference}</div>,
    },
    {
      field: "services",
      headerName: "Services",
      minWidth: 120,
      valueGetter: (params) => getServiceShipment(params.row),
    },
    {
      field: "mode",
      headerName: "Mode",
      minWidth: 80,
      align: "center",
      renderCell: (params) => <div>{params.row.mode?.toUpperCase()}</div>,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 120,
      align: "center",
      renderCell: (params) => (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status}
        </div>
      ),
    },
    {
      field: "created.at",
      headerName: "Created At",
      minWidth: 150,
      align: "center",
      filterOperators: [ ...getGridDateOperators() ],
      renderCell: (params) => <div>{fDateString(params.row.created?.at)}</div>,
      valueGetter: (params) => params.row.created?.at,
      sortComparator: dayInMonthComparator,
    },
    {
      field: "created.by",
      headerName: "Created By",
      minWidth: 130,
      align: "center",
      renderCell: (params) => {
        const by = params.row.created?.by;

        if (!by) return <>--</>;
        return <>{`${by?.firstname} ${by?.lastname}`}</>;
      },
      valueGetter: (params) => {
        const by = params.row.created?.by;
        return `${by?.firstname} ${by?.lastname}`;
      },
    },
  ];

  if (invalidShipments && tenant?.modules?.spotmarket) {
    cols.push({
      field: "metadata.rate",
      headerName: "Rate",
      minWidth: 120,
      renderCell: (params) => {
        const rate = params.row.metadata?.rate;
        const formattedRate = rate ? rate.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '';
        return formattedRate
      },
      valueGetter: (params) => {
        return params.row.metadata?.rate;
      },
    });
  }

  cols.push(...[
    {
      field: "integrated",
      headerName: "Confirmed",
      minWidth: 80,
      align: "center",
      renderCell: (params) =>
        params.row.integrated ? (
          <CheckBoxOutlined sx={{ opacity: 0.8 }} />
        ) : (
          <CheckBoxOutlineBlankOutlined sx={{ opacity: 0.8 }} />
        ),
    },
    {
      field: "quantity",
      headerName: "Pallets",
      minWidth: 100,
      filterOperators: [ ...customNumericOperators ],
    },
    {
      field: "weight",
      headerName: "Weight",
      minWidth: 100,
      filterOperators: [ ...customNumericOperators ],
    },
    {
      field: "equipment",
      headerName: "Equpment",
      minWidth: 100,
      valueGetter: (params) => ShipmentUtils.getEquipmentFromShipment(params.row) ?? "--",
    },
    {
      field: "carrier",
      headerName: "Carrier",
      minWidth: 100,
      valueGetter: (params) => getCarrierShipment(params.row),
    },
    {
      field: "shipper",
      headerName: "Shipper",
      minWidth: 250,
      renderCell: (params) => <div>{params.row.pickups[0]?.originName}</div>,
      valueGetter: (params) => params.row.pickups[0]?.originName,
    },
    {
      field: "pickups.originCity",
      headerName: "Origin City",
      minWidth: 200,
      renderCell: (params) => <div>{params.row.pickups[0]?.originCity}</div>,
      valueGetter: (params) => params.row.pickups[0]?.originCity,
    },
    {
      field: "pickups.originLocation",
      headerName: "Origin Adress",
      minWidth: 200,
      renderCell: (params) => <div>{params.row.pickups[0]?.originAddr1}</div>,
      valueGetter: (params) => params.row.pickups[0]?.originAddr1,
    },
    {
      field: "pickups[0].targetShipLate",
      headerName: "Ship Date",
      minWidth: 200,
      filterOperators: [ ...getGridDateOperators() ],
      renderCell: (params) => {
        <div>{fDateString(params.row.pickups[0]?.targetShipLate)}</div>;
      },
      // PASSES TO valueFormatter
      valueGetter: (params) => {
        return fDateString(params.row.pickups[0]?.targetShipLate);
      },
    },
    {
      field: "receiver",
      headerName: "Receiver",
      minWidth: 250,
      renderCell: (params) => <div>{params.row.stops[0]?.destName}</div>,
      valueGetter: (params) => params.row.stops[0]?.destName,
    },
    {
      field: "destinationCity",
      headerName: "Destination City",
      minWidth: 200,
      renderCell: (params) => <div>{params.row.stops[0]?.destCity}</div>,
      valueGetter: (params) => params.row.stops[0]?.destCity,
    },
    {
      field: "destinationLocation",
      headerName: "Dest. Address",
      minWidth: 200,
      renderCell: (params) => <div>{params.row.stops[0]?.destAddr1}</div>,
      valueGetter: (params) => params.row.stops[0]?.destAddr1,
    },
    {
      field: "stops[0].targetDeliveryLate",
      headerName: "Delivery Date",
      minWidth: 200,
      filterOperators: [ ...getGridDateOperators() ],
      renderCell: (params) => {
        return fDateString(params.row.stops[0]?.targetDeliveryLate);
      },
      valueGetter: (params) => {
        return fDateString(params.row.pickups[0]?.targetShipLate);
      },
    },
    {
      field: "trackingUrl",
      headerName: "Tracking URL",
      minWidth: 200,
      renderCell: (params) => <Button href={ShipmentUtils.getShipmentPublicInfoHref(params.row)}>Tracking</Button>
    },
    {
      field: "bolFile.url",
      headerName: "BOL",
      minWidth: 200,
      renderCell: (params) =>
        params.row.bolFile ? (
          <Link href={params.row.bolFile?.url}>Download BOL</Link>
        ) : (
          ""
        ),
    },
    {
      field: "podFile.url",
      headerName: "POD",
      minWidth: 200,
      renderCell: (params) =>
        params.row.podFile ? (
          <Link href={params.row.podFile?.url}>Download POD</Link>
        ) : (
          ""
        ),
    },
    {
      field: "bolFile.number",
      headerName: "BOL Number",
      minWidth: 200,
      renderCell: (params) => getBOLNumber(params.row),
    },
    {
      field: "ProNumber",
      headerName: "PRO Number",
      minWidth: 200,
      valueGetter: (params) => {
        return ShipmentUtils.getProNumber(params.row) ?? '--';
      }
    },
  ]);

  const actions_col = {
    field: "actions",
    headerName: "Actions",
    width: 150,
    renderCell: (params) => {
      return (
        <Box>
          {invalidShipments ? (
            <InvalidShipmentActions
              params={params}
              onApproveItem={onApproveItem}
              onDenyItem={onDenyItem}
            />
          ) : (
            <ValidShipmentActions
              params={params}
              onDuplicate={(e) => e.stopPropagation()}
              onEdit={(e) => e.stopPropagation()}
            />
          )}
        </Box>
      );
    },
  };

  if (invalidShipments) {
    cols.unshift(actions_col)
  } else {
    cols.push(actions_col)
  }

  return cols;
};

const ValidShipmentActions = ({ params, onDuplicate, onEdit }) => (
  <>
    <Tooltip title="Duplicate">
      <Button onClick={onDuplicate} href={`/shipments/${params.row._id}/edit?duplicate`}>
        <ControlPointDuplicate />
      </Button>
    </Tooltip>
    <Tooltip title="Edit">
      <Button onClick={onEdit} href={`/shipments/${params.row._id}/edit`}>
        <Edit />
      </Button>
    </Tooltip>
  </>
);

const InvalidShipmentActions = ({ params, onApproveItem, onDenyItem }) => (
  <>
    <Tooltip title="Approve">
      <Button onClick={(e) => { onApproveItem(e, params.row); }}>
        <Check />
      </Button>
    </Tooltip>
    <Tooltip title="Deny">
      <Button onClick={(e) => { onDenyItem(e, params.row); }}>
        <DoDisturb />
      </Button>
    </Tooltip>
  </>
);


const Unavailable = () => (
  <Button disabled sx={{ fontWeight: 200 }}>
    Unavailable
  </Button>
);

const getBOLNumber = (shipment) => {
  const BOLNumber = shipment.integrations?.find(
    (integration) =>
      integration.target == "banyan" &&
      integration.status == "success" &&
      !!integration?.metadata?.bolNumber
  )?.metadata.bolNumber;
  return BOLNumber || "";
};

const getCarrierShipment = (shipment) => {
  if (shipment.mode === "LTL") {
    const carrier = shipment.integrations?.find(
      (integration) => integration.status == "success"
    );
    return carrier?.metadata?.carrier ?? "--";
  } else {
    return "Trinity Logistic";
  }
};

const getServiceShipment = (shipment) => {
  const allServices = [
    ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL")
      .services,
    ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL")
      .pickups,
    ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "LTL")
      .stops,
    ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL")
      .services,
    ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL")
      .pickups,
    ...shipmentServices.find((servicePerMode) => servicePerMode.mode === "TL")
      .stops,
  ];

  if (shipment.services) {
    const shipmentServiceLabels = [
      ...new Set(
        allServices
          .filter(
            (service) =>
              shipment.services.hasOwnProperty(service.key) &&
              shipment.services[service.key]
          )
          .map((service) => service.label)
      ),
    ];

    if (shipmentServiceLabels.length > 0) {
      return shipmentServiceLabels.join(", ");
    }
  }

  return "--";
};

const fDateString = (dateString) => {
  if (!dateString) return " -- ";
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${month}/${day}/${year} ${hours}:${minutes}`;
};
