import { useMutation, useQuery } from "react-query";
import { CustomerService } from "src/api/customer.service";
import { useAuth } from "src/hooks/useAuth"

export const useCustomerQueries = () => {
    const { session } = useAuth();
    const service = new CustomerService(session.token, session.tenant._id);

    const { 
        refetch: getCustomers, 
        isLoading: isCustomersLoading, 
        data: customers 
    } = useQuery(
        'customers',
        { 
            queryFn: async () => { return service.getAll(); },
            enabled: false
        },
    );

    return {
        getCustomers,
        isCustomersLoading,
        customers,
    }
}