import { Box, Grid, ListItem, Typography } from "@mui/material";

export function ListItemCarriersDetail(props) {
  const { carrier, customProps } = props;  

  return (
    <>
      <ListItem
        {...customProps}        
        onClick={(event) => {
          customProps.onClick(event);          
        }}
      >
        <Grid container alignItems="center">
          <Grid item sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Carrier Name
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                noWrap
                fontWeight={600}
              >
                {carrier?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Address 1
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                fontWeight={600}
                noWrap
              >
                {carrier?.remitAddress1 ? carrier?.remitAddress1 : '--'}
              </Typography>
            </Box>
            <Box sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
              <Typography variant="body2" color="text.secondary">
                ZIP
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                noWrap
                fontWeight={600}
              >
                {carrier?.postalCode ? carrier?.postalCode : '--'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
}
