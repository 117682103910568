import { SpotmarketFormContext } from "../context/SpotmarketFormContext";
import { useContext } from "react";

export function useSpotmarketShipmentFormContext() {
  const context = useContext(SpotmarketFormContext);
  if (!context)
    throw new Error(
      "This hook can only be used inside the SpotmarketFormProvider"
    );

  return context;
}

