const { Card, CardHeader, CardContent, Grid, MenuItem } = require("@mui/material");
const { FormInput } = require("src/components/form/FormInput");
const { palletCountUnits } = require("src/pages/shipments/components/units");

export function ItemsFields({ UOM }) {
    return (
        <Card
            variant="outlined"
            sx={{
                py: "1.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
                pt: "1rem"
            }}
        >
            <CardHeader
                title={"Item Info"}
                sx={{ "&.MuiCardHeader-root": { p: 0 } }}
            />
            <CardContent
                sx={{
                    "&.MuiCardContent-root": { p: 0, py: "1rem" },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem",
                }}
            >
                <Grid container flex={1} columnGap={1}>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="ITEM_NUM" label={"Item Number"} fullWidth />
                    </Grid>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="QTY_OPEN" type="number" label={"Quantity Open"} fullWidth />
                    </Grid>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="EXTD_COST" type="number" label={"Extended Cost"} fullWidth />
                    </Grid>
                    <Grid itemxs={3} flex={1}>
                        <FormInput
                            name={`UOM`}
                            label="Unit"
                            defaultValue={
                                UOM
                                    ? UOM
                                    : palletCountUnits[6]
                            }
                            select
                            required
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: { sx: { maxHeight: "10rem" } },
                                },
                            }}
                            sx={{ minWidth: "6rem" }}
                            fullWidth
                        >
                            {palletCountUnits.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </FormInput>
                    </Grid>
                </Grid>
                <FormInput name="ITEM_DESC" label={"Item Description"} fullWidth />
            </CardContent>
        </Card>
    )
}