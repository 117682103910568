import {
  ControlPointDuplicate,
  LocalShippingOutlined,
  TaskAltOutlined,
  RestartAlt,
  ErrorOutline,
  AnnouncementOutlined,
  Check,
  DoDisturb,
  Download,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Tooltip } from "@mui/material";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import { useState } from "react";
import DialogResetShipmentStatus from "./DialogResetShipmentStatus";
import { useAuth } from "src/hooks/useAuth";
import { useTenant } from "src/hooks/useTenant";
import { isReadyToConfirm } from "src/utils/shipment";
import { useShipmentSpotMarketQueries } from "../../hooks/useShipmentSpotMarketQueries";

export function useActionables({
  onUncombineTapped,
  onApproveTapped,
  onDenyTapped,
  onToPendingTapped,
  onAppointmentRequired,
  onCandidateCarrier,
  onSupportTicket,
  onConfirmSpotMarket,
  onSendToSpotMarket
}) {

  const { session } = useAuth();
  const { tenant } = useTenant();

  const {
    shipment,
    confirmShipment,
    consolidateShipment,
    isConsolidateLoading,
    isConfirmationLoading,
    isBackofficeUser,
    isLTLManager,
    resetShipmentStatus,
    isResettingShipmentLoading,
    isUncombining,
    isAllowedToBook,
    isPendingLoading,
    isApprovingShipmentLoading,
    isDenyingShipmentLoading,
  } = useShipmentDetails();

  const {
    exportDAT,
    isExportingDAT
  } = useShipmentSpotMarketQueries();

  const [openDialogResetStatus, setOpenDialogResetStatus] = useState(false);

  const needsValidation =
    !!(shipment?.poNumber?.length > 0) &&
    !shipment.validated &&
    ((shipment.mode == "TL" &&
      !shipment.integrations?.find(
        (integration) => integration?.target == "trinity"
      )?.status == "success") ||
      (shipment.mode == "LTL" &&
        false && // TODO Remove when migrate validation to booking method.
        shipment.integrations?.find(
          (integration) => integration?.target == "banyan"
        )?.status == "success"));

  const toggleApproveModalVisibility = () => onApproveTapped();
  const toggleDenyModalVisibility = () => onDenyTapped();
  const toggleToPending = () => onToPendingTapped();
  const toggleUncombineModalVisibility = () => onUncombineTapped();
  const toggleAppointmentModalVisibility = () => onAppointmentRequired();
  const toggleCandidateCarrierModalVisibility = () => onCandidateCarrier();
  const toggleSupportTicketModalVisibility = () => onSupportTicket();
  const toggleSpotMarketModalVisibility = () => onConfirmSpotMarket();  
  const toggleSendToSpotmarketModalVisibility = () => onSendToSpotMarket();  

  const handlingExportDAT = (mode) => {
    exportDAT({ shipmentsIds: [shipment._id], mode: mode });
  }

  const handleConfirmShipment = () => {
    if (tenant.code === 'COX' && (shipment.mode === 'TL' || shipment.mode === 'PAR')) {
      toggleSendToSpotmarketModalVisibility();
    } else {
      confirmShipment();
    }
  }

  const actionables = [
    {
      key: "confirm-shipment",
      isShown:
        !shipment?.metadata?.pending?.status &&
        !shipment?.metadata?.spotmarket?.dummy?.isDummy &&
        ['TL', 'PAR'].includes(shipment.mode) &&
        (isBackofficeUser || (isAllowedToBook && !tenant.code === 'SNT')) &&
        !needsValidation && !shipment.integrated,
      Actionable: () => (
        <>
          <LoadingButton
            loading={isConfirmationLoading}
            loadingPosition="start"
            startIcon={<LocalShippingOutlined />}
            onClick={() => {
              if (
                (shipment?.services?.needsAppointmentPickup ||
                  shipment?.services?.needsAppointmentStop) &&
                isBackofficeUser
              ) {
                toggleAppointmentModalVisibility();
              } else {
                handleConfirmShipment();
                // confirmShipment();
              }
            }}
            variant="outlined"
            color={"primary"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
            }}
          >
            {"Confirm Shipment"}
          </LoadingButton>
        </>
      ),
    },
    {
      key: "confirmed-shipment",
      isShown:
        isAllowedToBook &&
        !needsValidation &&
        shipment.integrated,
      Actionable: () => (
        <>
          <LoadingButton
            startIcon={<TaskAltOutlined />}
            disabled={true}
            variant="outlined"
            color={"success"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
            }}
          >
            {"Confirmed"}
          </LoadingButton>
        </>
      ),
    },
    {
      key: "reconfirm-shipment",
      isShown:
        !shipment?.metadata?.pending?.status &&
        isAllowedToBook &&
        shipment.integrated,
      Actionable: () => (
        shipment.mode === "LTL" ? <></> : <Tooltip title="Update Confirmation">
          <LoadingButton
            loading={isConfirmationLoading}
            loadingPosition="start"
            startIcon={<LocalShippingOutlined width={"2rem"} />}
            onClick={confirmShipment}
            variant="outlined"
            color={"primary"}
          >
            Reconfirm
          </LoadingButton>
        </Tooltip>
      )
    },
    {
      key: "consolidate-shipment",
      isShown:
        !shipment?.metadata?.pending?.status &&
        shipment?.metadata?.spotmarket?.dummy?.isDummy &&
        tenant.modules?.spotmarket &&
        (isBackofficeUser) &&
        !needsValidation && !shipment.integrated,
      Actionable: () => (
        <>
          <LoadingButton
            loading={isConsolidateLoading}
            loadingPosition="start"
            startIcon={<LocalShippingOutlined />}
            onClick={() => { consolidateShipment({ shipmentId: shipment._id }) }}
            variant="outlined"
            color={"primary"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
            }}
          >
            {"Consolidate as Shipment"}
          </LoadingButton>
        </>
      ),
    },
    {
      key: "approve-shipment",
      isShown: shipment?.metadata?.pending?.status && session?.actions?.['ApproveDenyShipments'],
      Actionable: () => (
        <Tooltip title='Approve' >
          <LoadingButton
            loading={isApprovingShipmentLoading}
            loadingPosition="start"
            startIcon={<Check width={"2rem"} />}
            onClick={() => {
              if (tenant.modules?.spotmarket) {
                toggleSpotMarketModalVisibility()
              } else {
                toggleApproveModalVisibility()
              }
            }}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            Approve
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      key: "deny-shipment",
      isShown: shipment?.metadata?.pending?.status && session?.actions?.['ApproveDenyShipments'],
      Actionable: () => (
        <Tooltip title='Deny' >
          <LoadingButton
            loading={isDenyingShipmentLoading}
            loadingPosition="start"
            startIcon={<DoDisturb width={"2rem"} />}
            onClick={toggleDenyModalVisibility}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            Deny
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      key: "duplicate-shipment",
      isShown: !isLTLManager,
      Actionable: () => (
        <Tooltip title="Duplicate Shipment">
          <Button
            href={`/shipments/${shipment._id}/edit?duplicate`}
            sx={{
              display: "flex",
              gap: "0.5rem",
            }}
            variant="outlined"
          >
            <ControlPointDuplicate />
            Duplicate
          </Button>
        </Tooltip>
      ),
    },
    {
      key: "pending-shipment",
      isShown:
        !shipment?.metadata?.pending?.status &&
        shipment.status?.toLowerCase() === "ready" &&
        session?.actions?.['SetPendingShipments'],
      Actionable: () => (
        <Tooltip title='Add to Pending' >
          <LoadingButton
            loading={isPendingLoading}
            loadingPosition="start"
            startIcon={<ErrorOutline width={"2rem"} />}
            onClick={toggleToPending}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            Add To Pending
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      key: "export-dat",
      isShown:
        shipment?.metadata?.pending?.status &&
        tenant.modules?.spotmarket,
      Actionable: () => (
        <Tooltip title='Export DAT' >
          <LoadingButton
            loading={isExportingDAT}
            loadingPosition="start"
            startIcon={<Download width={"2rem"} />}
            onClick={() => { handlingExportDAT('DAT') }}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            Export DAT
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      key: "export-ts",
      isShown:
        shipment?.metadata?.pending?.status &&
        tenant.modules?.spotmarket,
      Actionable: () => (
        <Tooltip title='Export TS' >
          <LoadingButton
            loading={isExportingDAT}
            loadingPosition="start"
            startIcon={<Download width={"2rem"} />}
            onClick={() => { handlingExportDAT('TS') }}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            Export TS
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      key: "reset-shipment-status",
      isShown:
        isBackofficeUser &&
        shipment.integrated,
      Actionable: () => (
        <Tooltip title="Reset Shipment Status">
          <LoadingButton
            onClick={() => {
              setOpenDialogResetStatus(true);
            }}
            loading={isResettingShipmentLoading}
            sx={{
              display: "flex",
              gap: "0.5rem",
            }}
            variant="outlined"
          >
            <RestartAlt />
            Reset Shipment Status
          </LoadingButton>
          <DialogResetShipmentStatus
            onConfirm={() => {
              setOpenDialogResetStatus(false);
              resetShipmentStatus();
            }}
            onClose={() => {
              setOpenDialogResetStatus(false);
            }}
            open={openDialogResetStatus}
          />
        </Tooltip>
      ),
    },
    {
      key: "uncombine-shipment",
      isShown:
        !shipment?.metadata?.pending?.status &&
        isBackofficeUser &&
        !isLTLManager &&
        shipment.combined,
      Actionable: () => (
        <Tooltip title="Uncombine Shipment">
          <LoadingButton
            onClick={toggleUncombineModalVisibility}
            loading={isUncombining}
            sx={{
              display: "flex",
              gap: "0.5rem",
            }}
            variant="outlined"
          >
            Uncombine Shipment
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      key: "mark-valid-shipment",
      isShown: 
        shipment.metadata?.pending?.status &&
        tenant.modules?.spotmarket,
      Actionable: () => (
        <Tooltip title='Add Candidate Carrier' >
          <LoadingButton
            onClick={toggleCandidateCarrierModalVisibility}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            Add Candidate Carrier
          </LoadingButton>
        </Tooltip >
      ),
    },
    {
      key: "exception-shipment",
      isShown: shipment?.status === 'Delivered' || shipment?.status === "Completed",
      Actionable: () => (
        <Tooltip title='Exception' >
          <Button
            onClick={toggleSupportTicketModalVisibility}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            <AnnouncementOutlined />
            Exception
          </Button>
        </Tooltip>
      ),
    },
    // Temporarily disabling this
    // {
    //   key: "mark-valid-shipment",
    //   isShown: isBackofficeUser && shipment.metadata?.invalidPoNumber,
    //   Actionable: () => (
    //     <Tooltip title='Mark as Valid' >
    //       <LoadingButton
    //         onClick={validateShipment}
    //         loading={isValidateLoading}
    //         sx={{
    //           display: 'flex',
    //           gap: '0.5rem',
    //         }}
    //         variant="outlined"
    //       >
    //         Mark as Valid
    //       </LoadingButton>
    //     </Tooltip >
    //   ),
    // },
  ];

  return actionables;
}
