import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { useAuth } from "src/hooks/useAuth";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";
import { SearchPONumberField } from "./SearchPONumberField";
import { useFormContext } from "react-hook-form";
import { SelectPickupField } from "./SelectPickupField";
import { BasicPONumberField } from "./BasicPONumberField";
import { ShipmentUtils } from "src/utils";
import { useTenant } from "src/hooks/useTenant";
import { Order } from "src/models/order";

export function PONumberField() {
  const { session } = useAuth();
  const { tenant } = useTenant();
  const { watch, setValue, getValues } = useFormContext();
  const { mapFields } = useShipmentFormContext();

  const [order, setOrder] = useState(null);
  const [pickup, setPickup] = useState(null);

  const isBackOffice = session.permissions.IsBackOffice;
  const isSupplierManager = session.permissions.IsSupplierManager;
  const isThirdParty = session.user?.metadata?.thirdParty;

  const reset = () => {
    setOrder(null);
    setPickup(null);
  };

  // Handle order and its pickup
  const addOrder = (order) => {
    setOrder(order);
    setPickup(order.pickup[0]);
  };

  const replace = (pickup) => {
    const cloned = structuredClone(order);

    cloned.pickup = pickup;

    const shipmentAux = Order.toShipment({
      order: cloned,
      ignoreShipmentNumber: true,
      isLocationFormatted: isBackOffice,
    });
    mapFields(shipmentAux);
  }

  const SearchField = () => {
    const exaustive = (isBackOffice || isSupplierManager) && !isThirdParty;
    return <>
      <SearchPONumberField
        label={ShipmentUtils.getPONumberLabel(watch('tenant'))}
        onUpdated={(order) => {
          if (isEmpty(order)) reset();
        }}
        onSelect={(order) => {
          if (isThirdParty) {
            const poNumber = `${order.PO_NUM}-${order.RELEASE_NUM ?? 0}`;
            let poNumbers = getValues('poNumber')

            if (poNumbers?.length) poNumbers.push(poNumber);
            else poNumbers = [poNumber];

            setValue("poNumber", poNumbers)
          }

          addOrder(order);
        }}
        exaustive={exaustive}
        preload={isSupplierManager && !isThirdParty}
      />
      {(order !== null && order.pickup?.length > 1) && (
        <SelectPickupField labelId="select-pickup"
          value={pickup !== null ? pickup : {}}
          options={order.pickup}
          onChange={(event) => {
            setPickup(event.target.value);
            replace(event.target.value);
          }}
        />
      )}
    </>;
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={'100%'}
    >
      {(isSupplierManager || isBackOffice) && <SearchField />}
      {!(isSupplierManager || isBackOffice) && <BasicPONumberField label={ShipmentUtils.getPONumberLabel(tenant?._id)} />}
    </Box>
  );
}