import {
  useEffect,
  useState
} from "react";
import {
  useForm
} from "react-hook-form";
import {
  useSnackbar
} from "src/hooks/useSnackbar";
import { useTenant } from "src/hooks/useTenant";
import { Order } from "src/models/order";

export function useOrderFormHook(props) {
  const useFormParams = props?.useFormParams;
  const defaultValues = useFormParams?.defaultValues;    
  const { tenant } = useTenant();
  const {
    setSnackbarMessage
  } = useSnackbar();

  const form = useForm({
    ...useFormParams,
    defaultValues,
    mode: "onChange",
  });

  const {
    formState: {
      errors,
      isSubmitSuccessful
    },
    reset,
    handleSubmit,
    setError,
    clearErrors,
  } = form;


  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSubmitSuccessful) {
      props.reset && reset();
    }
  }, [isSubmitSuccessful, reset, errors]);

  const onSubmitHandler = async (values) => {
    setIsLoading(true);

    try {
      // map items
      let orderData = null
      orderData = Order.toJson({ ...values, tenant });

      const data = await props.onSave(orderData);
      if (data?.errors) {
        updateErrors(data.errors, setError);
      }

      setIsLoading(false);
    } catch (error) {
      setSnackbarMessage({
        severity: 'error',
        message: error.message
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    clearErrors,
  };
}

export function updateErrors(errors, setError) {
  errors.forEach((error) => {
    const param = error.param.replace("[", ".").replace("]", "");
    setError(param, {
      type: "manual",
      message: error.msg
    });
  });
}