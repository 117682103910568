import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { ShipmentUtils, SpotmarketUtils } from "src/utils";

export function SpotmarketEquipmentsDetails({ shipment }) {
  const equipments = shipment?.metadata?.spotmarket?.equipments || [];
  const mappedEquipments = SpotmarketUtils.getSpotmarketEquipments(equipments);

  return (
    <Card
      variant="outlined"
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: "4px",
        py: "0.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        mt: "0.75rem",
      }}>
      <Typography
        variant="h1"
        sx={{
          "&.MuiTypography-h1": {
            fontSize: "1.25rem",
          },
        }}>
        Equipments
      </Typography>
      <Divider />
      <Grid container spacing={1}>
        {mappedEquipments.length > 0 ? (
          mappedEquipments.map((equipment, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Box
                sx={{
                  border: 1,
                  borderColor: "grey.300",
                  borderRadius: "4px",
                  p: "0.5rem",
                  textAlign: "center",
                }}>
                <Typography variant="body1">
                  {equipment}
                </Typography>
              </Box>
            </Grid>
          ))
        ) : (
          <Typography sx={{ p: "0.5rem" }} variant="body1" color="textSecondary">
            No equipments available.
          </Typography>
        )}
      </Grid>
    </Card>
  );
}
