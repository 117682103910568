import { DateTimePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { LocationSearchField } from "./LocationSearchField/LocationsSearchField";

import {
  Typography,
  Stack,
  Box,
  Grid,
  MenuItem,
  Button,
  ListItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
} from "@mui/material";

import { getDayjsDate } from "src/utils/formatTime";
import { shipmentServices } from "../../constants/services";
import { ServiceField } from "./ServicesFields";
import { LimitedAccessField } from "./LimitedAccessField";

import { defaultTimezone, timezones } from "src/utils/timezones";
import { useSnackbar } from "src/hooks/useSnackbar";
import { canadaStates } from "src/pages/warehouses/constants/canadaStates";
import { mexicoStates } from "src/pages/warehouses/constants/mexicoState";
import { usaStates } from "src/pages/warehouses/constants/usaStates";
import { Edit, RemoveRedEyeOutlined } from "@mui/icons-material";
import { countries } from "../../constants/countries";
import { FormInputMask } from "src/components/form/FormInputMask";
import { useTenant } from "src/hooks/useTenant";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

const { FormInput } = require("src/components/form/FormInput");

export function LocationFields({ itemIndex = 0, onUpdated, isEdditing }) {
  const { setSnackbarMessage } = useSnackbar();
  const [params] = useSearchParams();
  const isDuplication = params.get('duplicate') !== null;

  const { control, getValues, setValue, watch } = useFormContext();

  const { tenant } = useTenant();
  const types = ["Pickup", "Delivery"];

  const [minEarlyDate] = useState(dayjs());
  const [minLateDate, setMinLateDate] = useState(dayjs());
  const [tz, setTz] = useState(null);

  useEffect(() => {
    setMinLateDate(getDayjsDate(minEarlyDate));
  }, [minEarlyDate]);

  useEffect(() => {
    setMinLateDate(
      getDayjsDate(getValues(`locations.${itemIndex}.targetEarly`))
    );
  }, [watch(`locations.${itemIndex}.targetEarly`)]);

  useEffect(() => {
    const timezone = getValues(`locations.${itemIndex}.timezone`);
    if (timezone)
      setTz(timezones.find((t) => t.name === (timezone || defaultTimezone)));
  }, [watch(`locations.${itemIndex}.timezone`)]);

  const getStates = () => {
    const country = getValues(`locations.${itemIndex}.country`);
    if (country === "CA") {
      return canadaStates;
    } else if (country === "MX") {
      return mexicoStates;
    } else {
      return usaStates;
    }
  };

  const [states, setStates] = useState(getStates());

  useEffect(() => {
    if (
      !states.some(
        (state) => state.acronym == getValues(`locations.${itemIndex}.state`)
      )
    )
      setValue(`locations.${itemIndex}.state`, "");
  }, [states]);

  const country = useMemo(() => {
    setStates(getStates());
    const country = getValues(`locations.${itemIndex}.country`);
    return country ?? "";
  }, [watch(`locations.${itemIndex}.country`)]);

  const location = watch(`locations.${itemIndex}`);
  const packages = watch(`items`);
  const mode = useMemo(() => watch("mode"), [watch("mode")]);

  const validate = () => {
    const location = getValues(`locations.${itemIndex}`);

    if (!location.type) {
      showError("Type field are required.");
      return false;
    }

    return true;
  };

  const showError = (message) => {
    setSnackbarMessage({
      message: <Box>{message}</Box>,
      severity: "error",
    });
  };

  const [fieldsAreSetManually, setFieldsManually] = useState(false);

  const hasPackages = useMemo(() => !!packages?.length, [packages]);
  const locationServices = useMemo(() => {
    if (mode) {
      const modeServices = shipmentServices.find((service) => service.mode === mode);      
      const locationTypeServices = modeServices[location?.type === 'pickup' ? 'pickups' : 'stops'];

      return locationTypeServices;
    }
  }, [location?.type, mode]);

  return (
    <Stack spacing={3} width={"100%"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          fontWeight={600}
          variant="h1"
          sx={{ "&.MuiTypography-h1": { fontSize: "1.25rem" } }}
        >
          {isEdditing ? "Edit location" : "Add location"}
        </Typography>
      </Box>

      <FormInput
        name={`locations.${itemIndex}.type`}
        label="Type"
        select
        type="text"
        fullWidth
        required
      >
        {types.map((type) => {
          return (
            <MenuItem key={type} value={type.toLocaleLowerCase()}>
              {type}
            </MenuItem>
          );
        })}
      </FormInput>
      {!!location?.type && (
        <LocationSearchField
          name={`locations.${itemIndex}.name`}
          index={itemIndex}
          locationType={getValues(`locations.${itemIndex}.type`)}
          InputProps={{
            endAdornment: (
              <Tooltip
                title={fieldsAreSetManually ? "Hide fields" : "Set manually"}
              >
                <IconButton
                  onClick={() => setFieldsManually(!fieldsAreSetManually)}
                >
                  {fieldsAreSetManually ? <RemoveRedEyeOutlined /> : <Edit />}
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      )}

      {/* DATES & TIMEZONE */}
      {location?.address1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              gap: 0.5,
            }}
          >
            <Controller
              control={control}
              name={`locations.${itemIndex}.targetEarly`}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <DateTimePicker
                  {...field}
                  value={getDayjsDate(field.value)}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  timezone="UTC"
                  minDate={minEarlyDate}
                  inputRef={ref}
                  sx={{ width: "100%" }}
                  label="Target Ship Early"
                />
              )}
            />
            <Controller
              control={control}
              name={`locations.${itemIndex}.targetLate`}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <DateTimePicker
                  {...field}
                  value={getDayjsDate(field.value)}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  timezone="UTC"
                  minDate={minLateDate}
                  inputRef={ref}
                  sx={{ width: "100%" }}
                  label="Target Ship Late"
                />
              )}
            />
          </Box>
          {tz && (
            <Typography component="h6" sx={{ mt: 0.5 }}>
              Timezone: {tz.name} / <small>{tz.rawFormat}</small>
            </Typography>
          )}
        </Box>
      )}

      {fieldsAreSetManually && (
        <>
          <FormInput
            name={`locations.${itemIndex}.address1`}
            label="Address 1"
            type="text"
            required
          />
          <FormInput
            name={`locations.${itemIndex}.address2`}
            label="Address 2"
            type="text"
          />
          <FormInput
            name={`locations.${itemIndex}.city`}
            label="City"
            type="text"
            required
          />
          <FormInput
            name={`locations.${itemIndex}.state`}
            label="State"
            select
            required
          >
            {states.map((state) => {
              return (
                <MenuItem key={state.acronym} value={state.acronym}>
                  {state.name}
                </MenuItem>
              );
            })}
          </FormInput>
          <FormInput
            name={`locations.${itemIndex}.country`}
            label="Country"
            select
            required
          >
            {countries.map((state) => {
              return (
                <MenuItem key={state.acronym} value={state.acronym}>
                  {state.name}
                </MenuItem>
              );
            })}
          </FormInput>
          <FormInput
            name={`locations.${itemIndex}.zip`}
            label="Zip"
            type="text"
            required
          />
          <FormInput
            name={`locations.${itemIndex}.contacts.0.name`}
            label="Contact"
            type="text"
            required
          />
          <FormInputMask
            name={`locations.${itemIndex}.contacts.0.phone`}
            selectCountry={country}
            required
          />
          <FormInput
            name={`locations.${itemIndex}.contacts.0.email`}
            label="E-Mail"
            type="text"
            required={tenant.code !== "CLI"}
          />
        </>
      )}

      {/* SET LOCATION PACKAGES */}
      {location?.address1 && (
        <>
          <FormControl>
            <InputLabel id={`locations.${itemIndex}.items`}>
              {`${!hasPackages
                ? "No package available to be selected"
                : "Packages"
                }`}
            </InputLabel>
            <Controller
              control={control}
              name={`locations.${itemIndex}.items`}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label="Packages"
                    select
                    multiple
                    type="text"
                    value={field.value ?? []}
                    fullWidth
                    required
                    disabled={!hasPackages}
                  >
                    {packages.map((pkg, index) => {
                      let description = [pkg.description];
                      if (pkg.primaryReference && !isDuplication)
                        description = [pkg.primaryReference, pkg.description];
                      else if (pkg.itemId)
                        description = [pkg.itemId, pkg.description];

                      return (
                        <MenuItem key={index} value={pkg._id ?? index}>
                          {description.join(" - ")}
                        </MenuItem>
                      );
                    })}
                  </Select>
                );
              }}
            />
          </FormControl>

          {/* SERVICES */}
          {mode && (
            <Box>
              <Typography fontWeight={600} pb={0.5}>
                Services
              </Typography>
              <Grid container columns={6} rowSpacing={0.5}>
                {locationServices.map((service, index) => {
                  return (
                    <ServiceField
                      service={service}
                      key={`${service.name}${index}`}
                      disabled={!hasPackages}
                    />
                  );
                })}
                <Grid item xs={12}>
                  <LimitedAccessField type={"location"} />
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}

      {!mode && (
        <Typography color="GrayText" variant="caption" fontWeight={600}>
          Select a Shipment mode to see available services
        </Typography>
      )}

      <Box>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            const isValid = validate();
            if (isValid) onUpdated(itemIndex);
          }}
          fullWidth
          sx={{ minWidth: "8rem", height: "2.5rem", mb: "3rem" }}
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
}
