import { Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import { useOrderQueries } from "./hooks/useOrderQueries";
import { OrderForm } from "./components/OrderForm";
import { useParams } from "react-router-dom";
import { Order } from "src/models/order";

export function UpdateOrderPage() {
  const { orderId }  = useParams();  

  const {
    order,
    getOrder,
    isOrderLoading,  
    updateOrder,
    isUpdateLoading
  } = useOrderQueries();

  useEffect(() => {           
    getOrder({orderId});    
  }, []);

  console.log(orderId)

  return (
    <Paper
      sx={{
        maxWidth: {
          lg: "1000px",
          xs: "90%",
        },
        margin: "auto",
      }}
      elevation={3}
    >
      <Helmet>
        <title>Update Order</title>
      </Helmet>
      <OrderForm
        type="Update"
        onSave={updateOrder}
        isSubmissionLoading={isUpdateLoading}                
        isLoading={isOrderLoading}
        useFormParams={{ defaultValues: order ? Order.fromJson(order) : '' }}
      />
    </Paper>
  );
}
