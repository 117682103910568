import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { OrderService } from "src/api/orders.service";
import { ErrorMessage } from "src/components/ErrorMessage";
import { useAuth } from "src/hooks/useAuth"
import { useSnackbar } from "src/hooks/useSnackbar";
import { orderColumns } from "src/pages/dashboard/orderColumns";
import * as XLSX from "xlsx";

export const useOrderQueries = () => {
  const { session } = useAuth();
  const { setSnackbarMessage } = useSnackbar();
  const navigate = useNavigate();

  const service = new OrderService(session.token, session.tenant._id);

  const { refetch: getOrders, isLoading: isOrdersLoading, data: orders } = useQuery(
    'orders',
    {
      queryFn: async () => { return service.getOrders(); },
      enabled: false,
    }
  );

  const {
    data: template,
    isLoading: isLoadingTemplate,
    mutate: getTemplate,
  } = useMutation({
    mutationFn: async () => {

      const columns = orderColumns();
      const data = [columns];

      // worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

      // xlsx
      const excelData = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelData], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);

      // download
      const a = document.createElement("a");
      a.href = url;
      a.download = "orders_template.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();

      // clear object
      URL.revokeObjectURL(url);
    },
  });

  const {
    isLoading: isUploadOrdersLoading,
    mutate: uploadOrdersFile,
    data: uploadedOrders,
    isSuccess: isUploadOrdersSuccess,
  } = useMutation({
    mutationFn: ({ file, fileType }) => {
      return service.uploadOrdersFile(file, fileType)
        .then((response) => {
          setSnackbarMessage({
            message: <Box> Success to upload {fileType} </Box>,
            severity: "success",
          });

          return response;
        })
        .catch((error) => {
          setSnackbarMessage({
            message: (
              <Box>
                {" "}
                Error to upload {fileType}: {error.message}
              </Box>
            ),
            severity: "error",
          });
        });
    },
  });


  const { mutate: create, isLoading: isCreationLoading } = useMutation({
    mutationFn: async (values) => {
      try {
        const data = await service.create(values);

        setSnackbarMessage({
          message: `New order created with PO Number: ${data.PO_NUM}`,
          severity: "success",
        });

        navigate("/orders");
      } catch (error) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error creating Order</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      }
    }
  });

  const {
    isLoading: isOrderLoading,
    mutate: getOrder,
    data: order,
  } = useMutation({
    mutationFn: ({ orderId }) =>
      service.getOrder(orderId).then((response) => {
        if (response.errors || response.messages || response.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1} fontWeight={600}>
                  Error to get Order
                </Typography>
                <ErrorMessage response={response} />
              </Box>
            ),
            severity: "error",
          });
        }
        if (response.length == 0) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography mb={1}>No Order Available</Typography>
              </Box>
            ),
            severity: "warning",
          });
        }
        if (response) return response;
      }),
    onError: (error) => {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    },
  });

  const { mutate: updateOrder, isLoading: isUpdateLoading } = useMutation({
    mutationFn: (order) =>
      service.updateOrder(order._id, order).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error updating Order</Typography>
                {data.errors?.map((error) => (
                  <Typography>
                    {error.msg} - {error.param}
                  </Typography>
                ))}
                <Typography>
                  {data.messages || data.message || "Error to update Order"}
                </Typography>
              </Box>
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Order successfully updated!`,
          severity: "success",
        });
        navigate(`/orders/${order._id}`);
        return data;
      }),
  });

  return {
    template,
    isLoadingTemplate,
    getTemplate,

    isUploadOrdersLoading,
    uploadOrdersFile,
    uploadedOrders,
    isUploadOrdersSuccess,

    getOrders,
    isOrdersLoading,
    orders,

    create,
    isCreationLoading,

    order,
    getOrder,
    isOrderLoading,

    updateOrder,
    isUpdateLoading
  }
}