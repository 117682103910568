import {
  Location
} from "./location";
import {
  Service
} from "./service";

const cityStateZipREGEX = /^(.*),\s*([A-Z]{2})\s*([\d-]+)$/;

export class Order {
  static toJson(order) {
    order.CITY_ST_ZIP = `${order.CITY}, ${order.STATE}, ${order.ZIP}`;
    delete order.CITY;
    delete order.STATE;
    delete order.ZIP;    

    order.TENANT = order.tenant._id;
    delete order.tenant
    
    return order
  }

  static fromJson(order) {
    const address = order.CITY_ST_ZIP.split(',');
    order.CITY = address[0];
    if(address.length === 2) {
      const stateAndZip = address[1].trim();
      order.STATE = stateAndZip.substring(0, 2);
      order.ZIP = stateAndZip.substring(3);
    } else {      
      order.STATE = address[1].trim();
      order.ZIP = address[2];  
    }
    
    return order
  }


  static getStop(order, isLocationFormatted) {
    if (order.stop) {
      return {
        ...Location.toShipment(order.stop, "delivery", isLocationFormatted),
      };
    } else {
      const [, city, state, zip] = order.CITY_ST_ZIP.match(cityStateZipREGEX);

      return {
        type: 'delivery',
        name: order.SHIP_TO_LOCATION ?? "",
        address1: order.ADDRESS1 ?? "",
        address2: order.ADDRESS2 ?? "",
        city: city,
        state: state,
        zip: zip,
      };
    }
  }

  static getPickup(order, isLocationFormatted) {
    if (!order.pickup) {
      return {
        type: 'pickup',
        [isLocationFormatted === 'location' ? 'name' : 'originName']: order.VENDOR_NM ?? "",
      }
    }

    const isArray = Array.isArray(order.pickup);
    if (isArray && order.pickup.length) {
      return {
        ...Location.toShipment(order.pickup[0], "pickup", isLocationFormatted),
      };
    }

    return {
      ...Location.toShipment(order.pickup, "pickup", isLocationFormatted),
    };
  }

  static toShipment({
    order,
    ignoreShipmentNumber = false,
    isLocationFormatted = true,
  }) {
    const pickup = this.getPickup(order, isLocationFormatted);
    const stop = this.getStop(order, isLocationFormatted);
    const shipmentNumber = !ignoreShipmentNumber ? [order.SHIPMENT_NUM ?? ""] : [];
    const orderNumbers = !ignoreShipmentNumber ? [`${order.PO_LINE_NUM}` ?? ""] : [];

    if (isLocationFormatted) {
      return {
        shipmentNumber,
        ordersNumbers: orderNumbers,
        locations: [
          pickup,
          stop,
        ],
        services: {
          ...Service.fromLocation(pickup, 'pickup'),
          ...Service.fromLocation(stop, 'stop'),
        },
      };
    }

    return {
      shipmentNumber,
      ordersNumbers: orderNumbers,
      pickups: [pickup],
      stops: [stop],
      services: {
        ...Service.fromPickup(pickup),
        ...Service.fromStop(stop),
      },
    };
  }

  static getPONumber(order) {
    return order ? `${order?.PO_NUM}${order?.RELEASE_NUM != undefined ? "-" + order.RELEASE_NUM : "" }` : "";
  }
}