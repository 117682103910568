import config from "src/config.js";
import { BaseService } from "./base.service";

const baseUrl = config.apiUrl;
const baseEndpoint = "/customer";

export class CustomerService extends BaseService {

  async getAll() {
    const uri = new URL(baseUrl + baseEndpoint);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  }

}