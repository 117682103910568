import {
  Box,
  Stack,
  MenuItem,
  Typography,
  Button,
  CardContent,
  Card,
  CardHeader
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Clear } from "@mui/icons-material";
import { timezones } from "src/utils/timezones";
import { WarehouseFormProvider } from "./context/WarehouseFormContext";
import { usaStates } from "./constants/usaStates";
import { useWarehouseForm } from "./hooks/useForm";
import { DocksFieldsWrapper } from "./form/dock-fields/DocksFieldsWrapper";
import { OperationHours } from "./form/OperationHoursFields";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useAuth } from "src/hooks/useAuth";
import { Container, Draggable } from "react-smooth-dnd";
import { FormInputMask } from "src/components/form/FormInputMask";

const { FormInput } = require("src/components/form/FormInput");

export function WarehouseForm({
  useFormParams,
  onSave,
  showPassword,
  reset,
  ...props
}) {
  const isSubmissionLoading = props.isSubmissionLoading;
  const { session } = useAuth();

  const isBackofficeUser = session.permissions.IsBackOffice;
    
  return (
    <WarehouseFormProvider
      useFormParams={useFormParams}
      onSave={onSave}
      reset={reset}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Card
        variant="outlined"
        sx={{
          py: "1.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <CardHeader
          title={"Basic Info"}
          sx={{
            "&.MuiCardHeader-root": { p: 0 },
          }}
        />
        <CardContent
          sx={{
            "&.MuiCardContent-root": { p: 0, py: "1rem" },
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          <Stack spacing={2}>
            <FormInput
              name={`name`}
              label="Name"
              required
              type="text"
              sx={{ flexGrow: 1 }}
              disabled={!session.permissions.IsAdmin}
            />
            <FormInput
              name={`location`}
              label="Address 1"
              required
              type="text"
              sx={{ flexGrow: 1 }}
              disabled={!session.permissions.IsAdmin}
            />
            <FormInput
              name={`location2`}
              label="Address 2"              
              type="text"
              sx={{ flexGrow: 1 }}
              disabled={!session.permissions.IsAdmin}
            />
            <Box sx={{ display: "flex", gap: 2 }}>
              <FormInput
                name={`city`}
                label="City"
                required
                type="text"
                sx={{ flexGrow: 1 }}
                disabled={!session.permissions.IsAdmin}
              />
              <FormInput
                sx={{ flexGrow: 1 }}
                name="state"
                label="State"
                defaultValue={usaStates[0]}
                select
                required
                disabled={!session.permissions.IsAdmin}
              >
                {usaStates.map((state) => {
                  return (
                    <MenuItem key={state.acronym} value={state.acronym}>
                      {state.name}
                    </MenuItem>
                  );
                })}
              </FormInput>
              <FormInput
                name={`zip`}
                label="Zip Code"
                required
                type="number"
                max={99999}
                min={1}
                sx={{ flexGrow: 1 }}
                disabled={!session.permissions.IsAdmin}
              />
            </Box>
            <FormInput
              sx={{ flex: 1 }}
              name="timezone"
              label="Timezone"
              defaultValue={
                timezones.find((t) => t.name == "America/Los_Angeles").name
              }
              select
              required
              disabled={!session.permissions.IsAdmin}
            >
              {timezones.map((tz) => {
                return (
                  <MenuItem key={tz.name} value={tz.name}>
                    {tz.rawFormat}
                  </MenuItem>
                );
              })}
            </FormInput>
            <Box sx={{ flex: 1 }}>
              <Controller
                name="daylightSaving"
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox {...field} checked={!!field.value}
                        disabled={!session.permissions.IsAdmin} />
                    }
                    label="Is Daylight Saving Time?"
                  />
                )}
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <DocksFieldsWrapper />

      <OperationHours disabled={!session.permissions.IsAdmin}/>

      <Contacts />

      <LoadingButton
        variant="contained"
        fullWidth
        type="submit"
        loading={isSubmissionLoading}
        sx={{ py: "0.8rem", mt: "1rem" }}
      >
        Save Warehouse
      </LoadingButton>
    </WarehouseFormProvider>
  );
}

function Contacts() {
  const { fieldsContact, removeContact, appendContact, moveContact } = useWarehouseForm();

  const onDragEnd = ({ removedIndex, addedIndex }) => {
    moveContact(removedIndex, addedIndex);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography component="h4">Contacts</Typography>
      <Container
        dragHandleSelector=".drag-handle"
        lockAxis="y"
        onDrop={onDragEnd}
      >
        {fieldsContact.map((contact, index) => {
          return (
            <Draggable spacing={2} key={contact.id}>
              <div className="drag-handle" style={{margin: 5}}>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <FormInput
                    name={`contacts[${index}].name`}
                    label="Name"
                    required
                    type="text"
                    sx={{ flexGrow: 1 }}
                  />
                  <FormInputMask
                    name={`contacts[${index}].phone`}
                    required
                    sx={{ flexGrow: 1 }}
                    selectCountry="USA"
                  />                  
                  <FormInput
                    name={`contacts[${index}].email`}
                    label="Email"
                    required
                    type="email"
                    sx={{ flexGrow: 1 }}
                  />
                  <Button
                    sx={{ ml: 0, mr: 0 }}
                    onClick={() => removeContact(index)}
                  >
                    <Clear />
                  </Button>
                </Box>
              </div>
            </Draggable>
          );
        })}
      </Container>

      <Button
        size="small"
        variant="text"
        align="left"
        onClick={() => appendContact({ name: "", phone: "", email: "" })}
      >
        + Add Contact
      </Button>
    </Box>
  );
}
