import {
  AttachFileOutlined,
  CloudUpload,
  CloudUploadOutlined,
  TaskAltOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useOrderQueries } from "../hooks/useOrderQueries";

export function UploadInput({ shipmentId, fileType, uploadCallback }) {
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);
  const openFileSelection = () => fileInputRef.current.click();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const { 
    isUploadOrdersLoading,
    uploadOrdersFile,
    uploadedOrders,
    isUploadOrdersSuccess } = useOrderQueries();

  const handleUpload = async () => {
    await uploadOrdersFile({ file: selectedFile, fileType });
    setSelectedFile(null);
  };

  useEffect(() => {
    if (isUploadOrdersSuccess) {
      uploadCallback(uploadedOrders);
    }
  }, [uploadedOrders]);

  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      {/* Hidden file input */}
      <input
        accept=".xlsx, .xls"
        id="file-upload"
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      {/* IconButton to trigger file input */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        { !selectedFile && (
          <label htmlFor="file-upload">
            <Tooltip title={`Select ${fileType} File`}>
              <LoadingButton
                size="small"
                loading={isUploadOrdersLoading}
                type="button"
                variant="outlined"
                sx={{
                  display: "flex",
                  px: 1,
                  gap: 2,
                }}                
                onClick={() => fileInputRef.current.click()}
              >
                <CloudUploadOutlined />
                <Typography
                  variant="button"
                  sx={{ width: "100%", textAlign: "left" }}
                >
                  Upload {fileType}
                </Typography>
              </LoadingButton>
            </Tooltip>
          </label>
        )}
        { selectedFile && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title={`Select Another File`}>
              <Button
                sx={{ px: "0.5rem", minWidth: "fit-content" }}
                onClick={openFileSelection}
              >
                {/* <Backspace /> */}
                <AttachFileOutlined />
              </Button>
            </Tooltip>
            <Tooltip title={`Upload ${selectedFile.name}`}>
              <LoadingButton
                size="small"
                loading={isUploadOrdersLoading}
                type="button"
                variant="outlined"
                sx={{
                  display: "flex",
                  px: 1,
                  gap: 2,
                }}
                onClick={handleUpload}
              >
                <CloudUpload />
                <Typography
                  variant="button"
                  sx={{ width: "100%", textAlign: "left" }}
                >
                  Send File
                </Typography>
              </LoadingButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
}
