import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";
import { useTenant } from "src/hooks/useTenant";

export function IntegrationFields() {
  const { control } = useFormContext();
  const { tenants } = useTenant();

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={"Integrations"}
        sx={{ "&.MuiCardHeader-root": { p: 0 } }}
      />
      <Stack gap={2}>
        <FormInput name={"integrations.banyan"} fullWidth label="Banyan" />
        <FormInput fullWidth name={"integrations.trinity"} label="Trinity" />
        <FormInput
          fullWidth
          name={"integrations.backoffice"}
          label="Backoffice"
        />
        <FormInput
          name="integrations.spotmarket"
          label="Spot Market"
          select
          sx={{ width: "100%" }}
        >
          { tenants
            .filter((tenant) => tenant.modules?.spotmarket)
            .map((tenant) => (
              <MenuItem key={tenant._id} value={tenant._id}>
                {tenant.name}
              </MenuItem>
            ))
          }
        </FormInput>
        <Controller
          control={control}
          name={"integrations.asn"}
          render={({ field }) => (
            <FormControlLabel
              sx={{ width: "100%", margin: 0 }}
              control={<Checkbox {...field} checked={!!field.value} />}
              label={`ASN`}
            />
          )}
        />
      </Stack>
    </Card>
  );
}
