import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { ItemDetails } from "src/pages/dashboard/components/ItemDetails";
import { valueGetter } from "../DetailsDrawer";
import { useTenant } from "src/hooks/useTenant";

export function ItemDetailsCard({ shipment }) {
    const { tenant } = useTenant();
    const isSpotmarket = tenant.modules?.spotmarket;
    const isPending = shipment.metadata?.pending?.status;
    const items = shipment.pickups.map(pickup => pickup.items).flat(Infinity);

    return (<Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        gap: "0.75rem",
        mt: "0.75rem"
    }}>
        <Card variant="outlined" sx={{
            py: "0.5rem",
            px: "1rem",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem"
        }}>
            <CardHeader component={() => <Box sx={{
                py: "0.25rem",
                pr: "0.5rem"
            }}>
                <Typography variant="h1" sx={{
                    "&.MuiTypography-h1": {
                        fontSize: "1.25rem"
                    }
                }}>
                    { !(isSpotmarket && isPending) ? 'Packages' : 'Commodities'}
                </Typography>
                <Typography color={"GrayText"}>
                    {valueGetter((items?.length), "No ")}{" "}
                    { !(isSpotmarket && isPending) ? 'Package(s)' : 'Commodity(s)'}
                </Typography>
            </Box>} sx={{
                "&.MuiCardHeader-root": {
                    p: 0
                }
            }} />
            {!!items?.length && <Divider />}
            <CardContent sx={{
                "&.MuiCardContent-root": {
                    p: 0
                }
            }}>
                <Grid container spacing={2}>
                    {items?.map(item => <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                        <ItemDetails 
                            item={item} 
                            key={item._id} 
                            isPending={shipment.metadata?.pending?.status}
                            expanded />
                </Grid>)}
                </Grid>
            </CardContent>
        </Card>
    </Box>);
}