import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { LocationDetails } from "src/components/location-details";

import { fDateString, fDateStringWithoutHours } from "src/utils/formatTime";

import { Location } from "src/models/location";
import { shipmentServices } from "../../../constants/services";
import { valueGetter } from "./DetailsDrawer";
import { ServiceField, ServicesDetails } from "./ServicesDetails";
import { ShipmentBasicInfo } from "./BasicInfo";
import { ShipmentUtils } from "src/utils";
import { useTenant } from "src/hooks/useTenant";

export function LocationsDetails({ shipment }) {
  const { tenant } = useTenant();
  const locations = ShipmentUtils.toLocations(shipment);

  return (
    <>
      {/* PICKUP & STOP */}
      <Card
        variant="outlined"
        sx={{
          border: 1,
          borderColor: "grey.300",
          borderRadius: "4px",
          py: "0.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
          mt: "0.75rem",
        }}>
        <Typography variant="h1" sx={{
          "&.MuiTypography-h1": {
            fontSize: "1.25rem"
          }
        }}>
          Locations
        </Typography>
        <Divider />
        <Grid
          container
          spacing={1}
        >
          {locations?.map((location, index) => {
            const services = shipmentServices.find((service) => service.mode === shipment.mode);
            const contact = location.contacts[0];

            return (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                <LocationDetails
                  locationLabel={location.type === 'pickup' ? "Origin" : "Destination"}
                  location={Location.fromShipmentLocation(location)}
                  type={location.type}
                  pending={shipment.metadata?.pending?.status}
                  customFields={
                    (!tenant.modules?.spotmarket && 
                      !shipment.metadata?.pending?.status) && (
                      <Stack>
                        <TargetDates
                          early={location.targetEarly}
                          late={location.targetLate}
                          estArrivalTime={location.estArrivalTime}
                          actualDate={location.actualArrival}
                        />
                        <Divider sx={{ mt: "0.8rem" }} />
                        <ContactInfo
                          name={contact?.name}
                          phone={contact?.phone}
                          email={contact?.email}
                        />
                        <Services
                          services={location.type === 'pickup' ? services?.pickups : services?.stops}
                          shipment={shipment}
                        />
                      </Stack>
                    )
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Card>
      {!tenant.modules?.spotmarket && (
        <Box
          sx={{
            border: 1,
            borderColor: "grey.300",
            borderRadius: "4px",
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            flexWrap: {
              xs: "nowrap",
              md: "wrap",
            },
            width: "100%",
            mt: "0.75rem",
            p: "1rem",
          }}
        >
          <ServicesDetails shipment={shipment} />
        </Box>
      )}
    </>
  );
}

function ContactInfo({ name, phone, email }) {
  return (
    <Box sx={{ mt: '0.5rem' }}>
      <Typography fontWeight={600} mt={1}>
        POC: {valueGetter(name)}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', xl: 'row' },
        justifyContent: 'space-between'
      }}>
        <Box sx={{
          display: {
            md: "flex",
            xs: "block"
          },
          width: '100%',
          py: "0.25rem",
          gap: "0.5rem"
        }}>
          <Typography>Phone:</Typography>
          <Typography fontWeight={600} textAlign={{
            md: "right",
            xs: "left"
          }}>
            {valueGetter(phone)}
          </Typography>
        </Box>
        <Box sx={{
          display: {
            md: "flex",
            xs: "block"
          },
          width: '100%',
          py: "0.25rem",
          gap: "0.5rem"
        }}>
          <Typography>E-Mail:</Typography>
          <Typography fontWeight={600} textAlign={{
            md: "right",
            xs: "left"
          }}>
            {valueGetter(email)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function Services({ services, shipment }) {
  const selectedServices = services.filter(service => {
    const [category, key] = service.name.split(".");
    return shipment?.[category] ? shipment?.[category][key] : false;
  });

  const isServiceNeeded = !!selectedServices.length;

  return (
    <Box py={1}>
      <Typography fontWeight={600}>Services</Typography>
      <Grid container gap={'1rem'}>
        {isServiceNeeded ? selectedServices.map((service, index) => {
          const [category, key] = service.name.split(".");
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} sx={{ display: 'flex' }}>
              <ServiceField service={service} key={service.name + index} checked={shipment?.[category] ? shipment?.[category][key] : false} />
            </Grid>
          );
        }) : <Typography>No one service is needed</Typography>}
      </Grid>
    </Box>
  );
}

function TargetDates({ early, late, estArrivalTime, actualDate }) {
  const { tenant } = useTenant();
  return (
    <>
      {!tenant?.modules?.spotmarket ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", xl: "row" },
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: {
                md: "flex",
                xs: "block",
              },
              width: "100%",
              gap: "0.5rem",
              py: "0.25rem",
            }}
          >
            <Typography>Target Early: </Typography>
            <Typography
              fontWeight={600}
              textAlign={{
                md: "right",
                xs: "left",
              }}
            >
              {valueGetter(fDateString(early))}
            </Typography>
          </Box>
          <Box
            sx={{
              display: {
                md: "flex",
                xs: "block",
              },
              width: "100%",
              gap: "0.5rem",
              py: "0.25rem",
            }}
          >
            <Typography>Target Late: </Typography>
            <Typography
              fontWeight={600}
              textAlign={{
                md: "right",
                xs: "left",
              }}
            >
              {valueGetter(fDateString(late))}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              md: "flex",
              xs: "block",
            },
            width: "100%",
            gap: "0.5rem",
            py: "0.25rem",
          }}
        >
          <Typography>Target Date: </Typography>
          <Typography
            fontWeight={600}
            textAlign={{
              md: "right",
              xs: "left",
            }}
          >
            {valueGetter(fDateStringWithoutHours(early))}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          whiteSpace: "nowrap",
          flexDirection: { xs: "column", xl: "row" },
        }}
      >
        {estArrivalTime && (
          <Box
            sx={{
              display: {
                md: "flex",
                xs: "block",
                // xl: "flex"
              },
              width: "100%",
              gap: "0.5rem",
              py: "0.25rem",
            }}
          >
            <Typography>Estimed Arrival time: </Typography>
            <Typography
              fontWeight={600}
              textAlign={{
                md: "right",
                xs: "left",
              }}
            >
              {valueGetter(fDateString(estArrivalTime))}
            </Typography>
          </Box>
        )}
        {actualDate && (
          <Box
            sx={{
              display: {
                md: "flex",
                xs: "block",
                // xl: "flex"
              },
              width: "100%",
              gap: "0.5rem",
              py: "0.25rem",
            }}
          >
            <Typography>Actual Date: </Typography>
            <Typography
              fontWeight={600}
              textAlign={{
                md: "right",
                xs: "left",
              }}
            >
              {valueGetter(fDateString(actualDate))}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
