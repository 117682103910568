import { Box } from "@mui/material";
import { useActionables } from "./actionables";
import DialogUncombineShipment from "./DialogUncombineShipment";
import DialogConfirmForShipments from "./DialogConfirmForShipments";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import { useEffect, useState } from "react";
import DialogAppointmentTime from "./DialogAppointmentTime";
import DialogCandidateCarrier from "./DialogCandidateCarrier";
import { ContactUsDialog } from "src/pages/support/contact-us/ContactUsDialog";
import { useAuth } from "src/hooks/useAuth";
import DialogAskForPendingShipments from "./DialogAskForPendingShipments";
import DialogConfirmForSpotMarket from "./DialogConfirmForSpotMarket";
import DialogMissingInformation from "./DialogMissingInformation";
import { useShipmentSpotMarketQueries } from "../../hooks/useShipmentSpotMarketQueries";
import DialogSendToSpotMarket from "./DialogSendToSpotMarket";

export function ShipmentActions({ setSelectedTabIndex }) {
  const {
    shipment,
    uncombine,
    pendingShipment,
    setShipmentAsPendingAndWaiting,
    confirmShipment,
    approveShipment,
    denyShipment,
    setAskForPending,
    askForPending,
    invalidOrders,
    addCandidateCarrier,
    missingInformations,
    setMissingInformations,
  } = useShipmentDetails();

  const {
    updateSpotmarket,
    isUpdateSpotLoading
  } = useShipmentSpotMarketQueries();

  const actionables = useActionables({
    onUncombineTapped: () => {
      setUncombineConfirmDialogVisilibity(true)
    },
    onToPendingTapped: () => {
      pendingShip()
    },

    onApproveTapped: () => {
      if (!session.tenant.modules?.spotmarket)
        setApproveConfirmDialogOpened(true)
      else
        setOpenConfirmSpotMarket(true)
    },

    onDenyTapped: () => {
      setDenyConfirmDialogOpened(true)
    },

    onAppointmentRequired: () => {
      setOpenDialogAppointTime(true);
    },
    onCandidateCarrier: () => {
      setOpenDialogCandidateCarrier(true);
    },
    onSupportTicket: () => {
      setOpenSupportModal(true);
    },
    onConfirmSpotMarket: () => {
      setOpenConfirmSpotMarket(true);
    },
    
    onSendToSpotMarket: () => {
      setSendToSpotMarketDialogOpened(true);
    },
  });

  useEffect(() => {
    if (askForPending) {
      setOpenDialogAskForPending(true);
    } else {
      setOpenDialogAskForPending(false);
    }
  }, [askForPending]);

  useEffect(() => {
    if (missingInformations) {
      setMissingInformations(true)
    } else {
      setMissingInformations(false);
    }
  }, [missingInformations])

  const [isUncombineConfirmDialogOpened, setUncombineConfirmDialogVisilibity] =
    useState(false);
  const [openDialogAppointTime, setOpenDialogAppointTime] = useState(false);
  const [openDialogAskForPending, setOpenDialogAskForPending] = useState(false);
  const [openDialogCandidateCarrier, setOpenDialogCandidateCarrier] = useState(false);
  const [openSupportModal, setOpenSupportModal] = useState(false);
  const [openConfirmSpotMarket, setOpenConfirmSpotMarket] = useState(false);  
  const { session } = useAuth();

  const handleClose = () => {
    setOpenSupportModal(false)
  }

  const appointmentPickup = shipment.services?.needsAppointmentPickup;
  const appointmentStop = shipment.services?.needsAppointmentStop;

  const userPhone = session.user.contact
    .filter((contact) => contact.type === 'tel')
    .map((contact) => contact.value)
    .join(", ")

  const handlePendingShipment = () => {
    setShipmentAsPendingAndWaiting();
    setOpenDialogAskForPending(false);
  }

  const [isApproveConfirmDialogOpened, setApproveConfirmDialogOpened] = useState(false);
  const [isDenyConfirmDialogOpened, setDenyConfirmDialogOpened] = useState(false);

  // dialog for "Send to SpotMarket"
  const [isSendToSpotMarketDialogOpened, setSendToSpotMarketDialogOpened] = useState(false);

  const pendingShip = () => {
    pendingShipment({ shipmentId: shipment._id });
  }

  const [shoudSendShipmentToSpotMarket, setShouldSendShipmentToSpotMarket] = useState(false);

  const handleShouldSendToSpotMarket = (shouldSend) => {
    confirmShipment(null, null, null, null, false, shouldSend);
    setShouldSendShipmentToSpotMarket(shouldSend);
    setSendToSpotMarketDialogOpened(false);
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {actionables
          .filter((action) => action.isShown)
          .map(({ Actionable, key }) => (
            <Actionable key={key} />
          ))}
      </Box>

      <DialogConfirmForShipments
        title={"Approve Shipment"}
        text={"Are you sure you want to approve this shipment?"}
        onConfirm={(e) => {
          setApproveConfirmDialogOpened(false);
          approveShipment();
        }}
        onClose={() => { setApproveConfirmDialogOpened(false) }}
        open={isApproveConfirmDialogOpened} />

      <DialogConfirmForShipments
        title={"Deny Shipment"}
        text={"Are you sure you want to deny and archive this shipment?"}
        onConfirm={(e) => {
          setDenyConfirmDialogOpened(false);
          denyShipment();
        }}
        onClose={() => { setDenyConfirmDialogOpened(false) }}
        open={isDenyConfirmDialogOpened} />

      <DialogUncombineShipment
        onConfirm={() => {
          setUncombineConfirmDialogVisilibity(false);
          uncombine({ shipmentId: shipment._id });
        }}
        onClose={() => {
          setUncombineConfirmDialogVisilibity(false);
        }}
        open={isUncombineConfirmDialogOpened}
      />
      <DialogAppointmentTime
        onConfirm={(selectedAppointmentTimePickup, selectedAppointmentTimeStop) => {
          setOpenDialogAppointTime(false);
          confirmShipment(null, null, null, null, selectedAppointmentTimePickup, selectedAppointmentTimeStop);
        }}
        onClose={() => {
          setOpenDialogAppointTime(false);
        }}
        open={openDialogAppointTime}
        appointmentPickup={appointmentPickup}
        appointmentStop={appointmentStop}
      />
      <DialogCandidateCarrier
        onConfirm={(rateCarrier, mcCarrier, etaCarrier, emptyOn, phone, notes) => {
          setOpenDialogCandidateCarrier(false);
          addCandidateCarrier(rateCarrier, mcCarrier, etaCarrier, emptyOn, phone, notes)
          setSelectedTabIndex("5")
        }}
        onClose={() => {
          setOpenDialogCandidateCarrier(false);
        }}
        open={openDialogCandidateCarrier}
        shipmentId={shipment._id}
        useFormParams={{ defaultValues: null }}
      />
      <ContactUsDialog
        isOpen={openSupportModal}
        handleClose={handleClose}
        name={session.user.firstname}
        email={session.user.email}
        phone={userPhone}
      />
      <DialogAskForPendingShipments
        open={openDialogAskForPending}
        title={"You can't Confirm this Shipment"}
        text={"There are some Orders below that need to be validated before. Would you like to set this Shipment as Pending?"}
        invalidOrders={invalidOrders}
        onIgnore={() => {
          confirmShipment(null, null, null, null, true, shoudSendShipmentToSpotMarket);
          setAskForPending(false);
        }}
        onConfirm={(e) => {
          handlePendingShipment(e)
        }}
        onClose={() => {
          setOpenDialogAskForPending(false);
          setAskForPending(false);
        }}
      />
      <DialogConfirmForSpotMarket
        open={openConfirmSpotMarket}
        title={"Fulfill shipment informations"}
        content={"You will be redirected to shipment form in order to fill the missing informations. "}
        shipment={shipment}
        useFormParams={{ defaultValues: shipment }}
        onSave={updateSpotmarket}
        isLoading={isUpdateSpotLoading}
        onClose={() => {
          setOpenConfirmSpotMarket(false);
        }}
      />
      <DialogMissingInformation
        open={missingInformations}
        title={"Fulfill shipment informations"}
        content={"You will be redirected to shipment form in order to fill the missing informations. "}
        shipmentId={shipment._id}
        useFormParams={{ defaultValues: shipment }}
        onSave={() => {
          updateSpotmarket()
          confirmShipment()
        }}
        isLoading={isUpdateSpotLoading}
        onClose={() => {
          setMissingInformations(false);
          setMissingInformations(false);
        }}
      />
      <DialogSendToSpotMarket
        open={isSendToSpotMarketDialogOpened}
        title={"Would you also like to send this shipment to Spot Market?"}
        text={"You can also send this shipment to Spot Market to look for a Carrier."}
        onIgnore={() => {
          setSendToSpotMarketDialogOpened(false);
        }}
        handleShouldSendToSpotMarket={handleShouldSendToSpotMarket}
        onClose={() => { 
          setSendToSpotMarketDialogOpened(false);
        }}
      />
    </>
  );
}
