import {
  Box,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { equipments } from "src/models/spotmarketEquipments";
import { Controller, useFormContext } from "react-hook-form";

export function SpotmarketEquipmentsField(props) {

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const { control } = useFormContext();

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        background: props.background
      }}
    >
      <CardHeader
        title={!props.filled ?? "Equipments"}
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
        }}
      />
      <FormControl>
        <InputLabel id="equipments-label">Equipments</InputLabel>
        <Controller
          control={control}
          name="metadata.spotmarket.equipments"
          render={({ field }) => (
            <Select
              {...field}              
              label="Equipments"
              multiple={props.multiple ?? true }
              fullWidth
              control={control}
              value={field.value || []}
              name="metadata.spotmarket.equipments"
              MenuProps={MenuProps}
            >
              {props.equipments?.length > 0 || props.filled ? (
                props.equipments
                  .map((equipment) =>
                    equipments.find((equip) => equip.key === equipment)
                  )
                  ?.map((equip) => (
                    <MenuItem key={equip.key} value={equip.key}>
                      {equip.label}
                    </MenuItem>
                  ))
              ) : equipments.length > 0 ? (
                equipments.map((equipment) => (
                  <MenuItem key={equipment.key} value={equipment.key}>
                    {equipment.label}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No equipments available</MenuItem>
              )}

            </Select>
          )}
        />
      </FormControl>
    </Card>
  );
}