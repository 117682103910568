import { Box, Typography } from "@mui/material";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { SpotmarketService } from "src/api/shipmentSpotMarket.service";
import { ErrorMessage } from "src/components/ErrorMessage";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";
import { ShipmentUtils } from "src/utils";

export function useShipmentSpotMarketQueries() {  
  const { session } = useAuth();
  const service = new SpotmarketService(session.token, session.tenant._id);  
  const { setSnackbarMessage } = useSnackbar();

  const navigate = useNavigate();

  const { mutate: createSpotmarketShipment, isLoading: isCreationSpotmarketLoading } = useMutation({
    mutationFn: async (values) => {
      const uploadablePackageSlips = ShipmentUtils.getUploadablePackageSlips(values);

      const uploadableAttachment = ShipmentUtils.getUploadableAttachment(values);

      try {
        const data = await service.createSpotmarketShipment(values);
        
        for (const packageSlip of uploadablePackageSlips) {
          await service.uploadPackageFile(data._id, packageSlip.file, packageSlip.itemId);
        }

        for (const attachment of uploadableAttachment) {
          await service.uploadAttachmentFile(data._id, attachment.file);
        }

        setSnackbarMessage({
          message: `New shipment created with Primary Reference: ${data.primaryReference}`,
          severity: "success",
        });

        navigate("/pending-shipments");
      } catch (error) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error creating Shipment</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      }
    }
  });

  const { mutate: updateSpotmarket, isLoading: isUpdateSpotLoading } = useMutation({
    mutationFn: async (values) => {      
      try {
        const data = await service.updateSpotmarket(values._id, values);        

        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error updating Shipment</Typography>
                {data.errors?.map((error) => (
                  <Typography>
                    {error.msg}
                  </Typography>
                ))}
                <Typography>
                  {data.messages || data.message }
                </Typography>
              </Box>
            ),
            severity: "error",
          });
          return data;
        }

        navigate(`/shipments/${data?._id}/spotmarket/approve`);
      } catch (error) {
        setSnackbarMessage({
          message: (
            <Box>
              <Typography mb={1}>Error updating Shipment</Typography>
              <Typography>{error.message}</Typography>
            </Box>
          ),
          severity: "error",
        });
      }
    }
  });

  const { mutate: updateSpotmarketShipment, isLoading: isUpdateSpotmarketLoading } = useMutation({
    mutationFn: (shipment) =>
      service.updateSpotmarketShipment(shipment._id, shipment).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error updating Shipment</Typography>
                {data.errors?.map((error) => (
                  <Typography>
                    {error.msg}
                  </Typography>
                ))}
                <Typography>
                  {data.messages || data.message || "Error to update Shipment"}
                </Typography>
              </Box>
            ),
            severity: "error",
          });
          return data;
        }
        setSnackbarMessage({
          message: `Shipment successfully updated!`,
          severity: "success",
        });

        navigate(`/pending-shipments/${shipment._id}`);
        return data;
      }),
  });

  const {
    data: exportedDAT_TS,
    isLoading: isLoadingExportDAT_TS,
    mutate: exportDAT_TS,
  } = useMutation({
    mutationFn: async ({ shipmentsIds, mode }) => {      
      return service.exportSpotmarketDatTs(shipmentsIds, mode).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <ErrorMessage
                response={data}
                defaultMessage={"Error to Export data"}
              />
            ),
            severity: "error",
          });
          return data;
        }

        setSnackbarMessage({
          message: `Your file is ready. Downloading...`,
          severity: "success",
        });

        const blob = new Blob([data], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = mode == "TS" ? "TS.csv" : "DAT.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();

        window.URL.revokeObjectURL(url);
        return data;
      });
    },
    enabled: false,
  });

  const { mutate: consolidateShipment, isLoading: isConsolidateLoading } =
  useMutation({
    mutationFn: ({ shipmentId, callback }) =>
      service.consolidateShipment(shipmentId).then((data) => {
        if (data.errors || data.messages || data.message) {
          setSnackbarMessage({
            message: (
              <Box>
                <Typography>Error validated shipment</Typography>
              </Box>
            ),
            severity: "error",
          });

          return data;
        }
        setSnackbarMessage({
          message: `Shipment consolidated!`,
          severity: "success",
        });

        if (callback) callback(data);
      }),
  });


  return {
    createSpotmarketShipment,
    isCreationSpotmarketLoading,
    updateSpotmarketShipment,
    isUpdateSpotmarketLoading,

    exportedDAT: exportedDAT_TS,
    isLoadingExportDAT: isLoadingExportDAT_TS,
    exportDAT: exportDAT_TS,

    updateSpotmarket,
    isUpdateSpotLoading,
    
    consolidateShipment,
    isConsolidateLoading,
  };
}
