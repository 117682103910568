import { useTenant } from "src/hooks/useTenant";
import { useAuth } from "src/hooks/useAuth";
import { useLocation, useParams } from "react-router-dom";
import { ShipmentService } from "src/api/shipment.service";
import { useSnackbar } from "src/hooks/useSnackbar";
import { Shipment } from "src/models/shipment";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";

import { UpdateRegularShipmentPage } from "./UpdateRegularShipmentPage";
import { UpdateSpotmarketShipmentPage } from "./UpdateSpotmarketShipmentPage";
import { ShipmentUtils } from "src/utils";

export function UpdateShipmentPage({useRegularForm}) {
  const { session } = useAuth();
  const service = new ShipmentService(session.token, session.tenant._id);

  const { tenant } = useTenant();
  const { setSnackbarMessage } = useSnackbar();

  const { shipmentId } = useParams();
  const [ shipment, setShipment ] = useState(null);

  const searchParams = new URLSearchParams(useLocation().search);
  const [ isDuplication ] = useState(searchParams.get("duplicate") != null);
  const [ type ] = useState(isDuplication ? "duplicate" : "update");
  const [duplicateSpotmarket, setDuplicateSpotmarket] = useState(false); 

  const { refetch: getShipment, isLoading } = useQuery("shipment", {
    queryFn: () =>
      service
        .getShipment(shipmentId)
        .then((shipment) => {
          if (shipment.message) {
            throw Error(shipment.message);
          }
          const editableShipment = Shipment.fromJson(shipment, type);
          setDuplicateSpotmarket(isDuplication & ShipmentUtils.validateAddress(editableShipment));
          setShipment(editableShipment);
        })
        .catch((error) => {
          setSnackbarMessage({
            message: `Failed to load shipment data - ${error}`,
            severity: "error",
          });
        }),
    enabled: false,
  });

  useEffect(() => {
    getShipment()
  }, [])

  const renderPage = () => {    

    if (useRegularForm || duplicateSpotmarket)
      return (
        <UpdateRegularShipmentPage 
          type={type}
          isDuplication={isDuplication}
          shipment={shipment} 
          isLoading={isLoading} />)
    
    if (tenant.modules?.spotmarket && shipment?.metadata?.pending?.status)
      return (
        <UpdateSpotmarketShipmentPage 
          type={type}
          isDuplication={isDuplication}
          shipment={shipment} 
          isLoading={isLoading} />)

    return (
      <UpdateRegularShipmentPage 
        type={type}
        isDuplication={isDuplication}
        shipment={shipment} 
        isLoading={isLoading} />)
  }

  return (
    <> { renderPage() } </>
  )

}
