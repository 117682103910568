import config from "src/config";
import { BaseService } from "./base.service";

const baseUrl = `${config.apiUrl}/support`;
const getIssuesType = `/issues`;
// const getPublicIssuesType = `/public-issues`;

export class SupportService extends BaseService {
  getIssuesType = async (filter, isPublic) => {
    const uri = new URL(`${baseUrl}${getIssuesType}`);
    if (filter) uri.searchParams.append('type', filter);

    const response = await fetch(uri.toString(), this.header());
    return response.json();
  };
  
  contactUs = async (data) => {
    const response = await fetch(`${baseUrl}/contact-us`, 
      this.header({
      method: "POST",
      body: JSON.stringify(data),
    }));
    
    if (response.status !== 200) throw new Error('Failed to send contact us request.');
  
    return response.json();
  };  
} 