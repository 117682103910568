import { createContext } from "react";
import { useOrderFormHook, useShipmentFormHook } from "../hooks/useOrderFormHook";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";

export const OrderFormContext = createContext(null);

export function OrderFormProvider({ children, ...props }) {
  const useFormParams = props.useFormParams;
  const onSave = props.onSave;
  const reset = props.reset || false;
  const onError = props.onError;  

  const {
    isLoading,
    form,
    handleSubmit,
    onSubmitHandler,
    setError,
    clearErrors,    
    ...rest
  } = useOrderFormHook({ useFormParams, onSave, reset, onError })

  return (
    <OrderFormContext.Provider
      value={{
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        clearErrors,        
        ...rest,
      }}
    >
      <FormProvider {...form}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={(event) => {            
            clearErrors();            
            handleSubmit(onSubmitHandler)(event);
          }}
          sx={props.sx}
        >
          { children }
        </Box>
      </FormProvider>
    </OrderFormContext.Provider>
  );
}