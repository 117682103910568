import * as React from 'react';
import { Button, Card, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { StandardDialog } from 'src/components/dialog/Dialog';
import { FieldsDialogApprove } from './FieldsDialogApprove';
import { LoadingButton } from '@mui/lab';
import { SpotmarketFormProvider } from '../../context/SpotmarketFormContext';

function DialogConfirmForSpotMarket({shipment, open, onClose, onSave, title, content, useFormParams, isUpdateSpotLoading}) {
    const locationPickup = shipment?.pickups[0];
    const locationStop = shipment?.stops[0];

    return (
        <StandardDialog isOpen={open} onClose={onClose}>
            <SpotmarketFormProvider                
                reset={false}
                onSave={onSave}
                useFormParams={useFormParams}
                sx={{ width: "100%" }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>{content}</DialogContentText>
                    <Card
                        variant="outlined"
                        sx={{
                            py: "1.5rem",
                            px: "1rem",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.75rem",
                            background: "transparent",
                        }}
                    >
                        <FieldsDialogApprove
                            locationPickup={locationPickup}
                            locationStop={locationStop}
                            shipment={shipment}
                        />
                    </Card>
                </DialogContent>
                <DialogActions sx={{ pb: 2, pr: 3 }}>
                    <Button variant="outlined" onClick={onClose} >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={isUpdateSpotLoading}
                        type="submit"
                    >
                        Redirect
                    </LoadingButton>
                </DialogActions>
            </SpotmarketFormProvider>
        </StandardDialog>
    );
}

export default DialogConfirmForSpotMarket;