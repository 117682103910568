import config from "src/config";
import {
    BaseService
} from "./base.service";

export class SpotmarketService extends BaseService {
    updateSpotmarketShipment = async (shipmentId, data) => {
        const uri = new URL(config.apiUrl + `/spotmarket/shipment/${shipmentId}`);

        const response = await fetch(
            uri.toString(),
            this.header({
                body: JSON.stringify(data),
                method: "PUT"
            })
        );

        return await response.json();
    };

    exportSpotmarketDatTs = async (shipmentIds, mode) => {
        const uri = new URL(`${config.apiUrl}/spotmarket/export/`);

        const response = await fetch(
            uri.toString(),
            this.header({
                method: 'POST',
                body: JSON.stringify({ shipmentIds, mode })
            })
        );

        if (!response.ok) throw new Error(`Erro na exportação: ${response.statusText}`);

        return await response.blob();
    };

    createSpotmarketShipment = async (data) => {
        const uri = new URL(config.apiUrl + '/spotmarket/shipment');

        const response = await fetch(
            uri.toString(),
            this.header({
                method: 'POST',
                body: JSON.stringify(data)
            })
        );

        const shipmentResponseJson = await response.json();

        if (!response.ok) {
            const errorMessage = getErrorMessage(shipmentResponseJson);
            throw new Error(`Error while creating shipment: \n ${errorMessage}`);
        }
        return shipmentResponseJson;
    }

    consolidateShipment = async (shipmentId) => {
        const uri = new URL(config.apiUrl + `/spotmarket/shipment/${shipmentId}/consolidate`);

        const response = await fetch(
            uri.toString(),
            this.header({
                method: 'POST',
            })
        );

        const shipmentResponseJson = await response.json();

        if (!response.ok) {
            const errorMessage = getErrorMessage(shipmentResponseJson);
            throw new Error(`Error while consolidating shipment: \n ${errorMessage}`);
        }
        return shipmentResponseJson;
    }

    updateSpotmarket = async (shipmentId, data) => {
        const uri = new URL(config.apiUrl + `/spotmarket/shipment/${shipmentId}/approve`);

        const response = await fetch(
            uri.toString(),
            this.header({
                body: JSON.stringify(data),
                method: "PUT"
            })
        );

        return await response.json();
    };
}

function getErrorMessage(error) {
    let errorMessage;

    if (error.message)
        errorMessage = error.message;
    if (error.msg)
        errorMessage = error.msg;
    if (error.messages) {
        if (Array.isArray(error.messages)) {
            errorMessage = error.errors.join(', ');
        }
    }
    if (error.errors && Array.isArray(error.errors)) {
        errorMessage = error.errors.map(error => error.msg).join(', ');
    }

    return errorMessage;
}