import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers";
import { getDayjsDate } from "src/utils/formatTime";
import { FormInput } from "src/components/form/FormInput";
import { ShipmentCarrierFormProvider } from "../../context/ShipmentCarrierFormContext";
import { LoadingButton } from "@mui/lab";
import { useShipmentQueries } from "../../hooks/useShipmentQueries";
import { useSnackbar } from "src/hooks/useSnackbar";
import { FormInputMask } from "src/components/form/FormInputMask";

function DialogCandidateCarrier(props) {
  const { getCandidateCarrier, candidateCarriers } = useShipmentQueries();
  const { setSnackbarMessage } = useSnackbar();

  const [rateCarrier, setRateCarrier] = useState("");
  const [mcCarrier, setMcCarrier] = useState("");
  const [etaCarrier, setEtaCarrier] = useState(null);
  const [emptyOn, setEmptyOn] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [ carriers, setCarriers ] = useState(null)

  useEffect(() => {
    setRateCarrier("");
    setMcCarrier("");
    setEtaCarrier(null);
    setEmptyOn("");
  }, []);

  const handleExistMCNumber = async () => {
    const shipmentId = props.shipmentId;
    await getCandidateCarrier({ shipmentId });
    setCarriers(candidateCarriers)
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="md"
      sx={{
        backdropFilter: "blur(5px) sepia(5%)",
      }}
      PaperProps={{ sx: { borderRadius: "16px" } }}
    >
      <ShipmentCarrierFormProvider
        onSave={() =>
          props.onConfirm(rateCarrier, mcCarrier, etaCarrier, emptyOn, phone, notes)
        }
        reset={props.reset ? props.reset : false}
        useFormParams={props.useFormParams ? props.useFormParams : null}
        candidatesCarriers={carriers}
      >
        <DialogContent
          style={{
            overflow: "hidden",
            padding: 3,
          }}
        >
          <Box
            sx={{
              width: "100%",
              p: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <DialogTitle> Candidate Carrier</DialogTitle>
            <FormInput
              name={`mc`}
              label="MC#"
              type="number"
              sx={{ width: "100%" }}
              value={mcCarrier}
              onChange={(e) => setMcCarrier(e.target.value)}
              required={true}
            />
            <FormInput
              name={`rate`}
              label="Rate"
              type="text"
              sx={{ width: "100%" }}
              value={rateCarrier}
              onChange={(e) => setRateCarrier(e.target.value)}
            />
            <Controller
              control={props.control}
              name={`eta`}
              defaultValue={null}
              rules={{ required: "ETA is required" }}
              render={({ field: { ref, ...field }, fieldState }) => (
                <DateTimePicker
                  {...field}
                  value={getDayjsDate(field.value)}
                  onChange={(date) => {
                    if (
                      date &&
                      date.isValid &&
                      date.isValid() &&
                      !isNaN(date.valueOf())
                    ) {
                      const formattedDate = date.toISOString();
                      field.onChange(date);
                      setEtaCarrier(formattedDate);
                    } else {
                      field.onChange(date);
                      setEtaCarrier(null);
                    }
                  }}
                  error={!!fieldState}
                  helperText={fieldState.error?.message}
                  timezone="UTC"
                  inputRef={ref}
                  size="small"
                  sx={{ width: "100%" }}
                  label="ETA"
                />
              )}
            />
            <FormInput
              name={`phone`}
              label="Phone"
              type="text"
              value={phone}
              sx={{ width: "100%" }}
              onChange={(e) => setPhone(e.target.value)}
            />
            <FormInput
              name={`emptyOn`}
              label="Empty In"
              type="text"
              sx={{ width: "100%" }}
              value={emptyOn}
              onChange={(e) => setEmptyOn(e.target.value)}
              defaultValue={""}
            />
            <FormInput
              name="notes"
              label="Notes"
              placeholder="Write some notes here"
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
              multiline
              rows={3}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            fullWidth
            type="submit"
            onClick={() => {
              handleExistMCNumber();
            }}
            sx={{ width: "100%", ml: 2, mr: 2, mb: 3, p: 2 }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </ShipmentCarrierFormProvider>
    </Dialog>
  );
}

export default DialogCandidateCarrier;
