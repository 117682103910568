export const userDetailsColumns = [
  {
    key: "firstname",
    label: "First Name",
  },
  {
    key: "lastname",
    label: "Last Name",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "role.name",
    label: "Role",
    renderValue: (params) => (params.role?.name)
  },
  {
    key: "role.relationship",
    label: "Relationship",
    renderValue: (params) => {
      const role = params.role;
      
      if (role) {
        if (role?.name === 'WarehouseManager' || role?.name === 'Contractor') {
          return <>{role.warehouses.map(w => w.name).join(', ')}</>
        } else if (role?.name === 'SupplierManager') {
          return <>{role.vendors.map(v => v.name).join(', ')}</>
        } else if(role?.name === 'Driver') {
          return <>{role?.carrier?.map(v => v.name).join(', ')}</>
        }     
      }

      return <>{'--'}</>;
    },
  },
  {
    key: "contact",
    label: "Contact",
    renderValue: (params) => {
      return (
        <div>{params.contact && params?.contact?.length > 0 ? params.contact.map(r => `${r.type}: ${r.value}`).join(', ') : ''}</div>
      )
    },
  }
];
