import React from "react";
import { useAuth } from "src/hooks/useAuth";

import BackofficeShipmentsPage from "./BackofficeShipmentsPage";
import RegularShipmentsPage from "./RegularShipmentsPage";

export default function ShipmentsPage() {
  const { session } = useAuth();
  const auth = session?.permissions;
  
  const renderPage = () => {

    if (auth?.IsBackOffice || auth?.IsLTLManager) 
      return <BackofficeShipmentsPage />

    return <RegularShipmentsPage />;
  }

  return (
    <> { renderPage() } </>
  )
}
