import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import { dimensionsUnits, palletCountUnits, weightUnits } from "../../units";
import { useSpotmarketPackageForm } from "src/pages/shipments/context/SpotmarketPackageContext";
import { FreightClassUtils } from "src/utils/freight";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

export function SpotmarketPackageSpecificationForm() {
  const { state, packageIndex } = useSpotmarketPackageForm();
  const { getValues, setValue, watch } = useFormContext();

  const mode = watch("mode");

  useEffect(() => {
    if (mode === "TL" && !getValues(`items.${packageIndex}.actualWeight`)) {
      setValue(`items.${packageIndex}.actualWeight`, 42000);
    }
  }, [mode, packageIndex, setValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        my: "1rem",
      }}
    >
      {/* DIMENSIONS */}
      <Typography variant="caption" fontSize={"1.2rem"} fontWeight={600}>
        Specification
      </Typography>

      {/* LENGTH */}
      { mode === "PAR" && (
      <Box>
        <Typography variant="caption" fontSize={"1rem"}>
          {`The total Length of all ${state.quantityLabel}`}
        </Typography>
        <Box marginTop={"0.5rem"} />
        <Stack
          spacing={1}
          direction={{
            xs: "column",
            md: "row",
          }}
        >
          <FormInput
            name={`items.${packageIndex}.actualLength`}
            label="Length"
            type="number"
            min={0}
            onWheel={(event) => event.target.blur()}

            required
            fullWidth
          />
            <FormInput
              name={`items.${packageIndex}.actualDimensionsUOM`}
              label="Unit"
              defaultValue={
                getValues("dimensionUOM")
                  ? getValues("dimensionUOM")
                  : dimensionsUnits[0].label
              }
              select
              required

              SelectProps={{
                MenuProps: {
                  PaperProps: { sx: { maxHeight: "10rem" } },
                },
              }}
              sx={{ width: "100%" }}
              fullWidth
            >
              {dimensionsUnits.map((option) => (
                <MenuItem key={option.label} value={option.label}>
                  {`${option.abreviation} - ${option.label}`}
                </MenuItem>
              ))}
            </FormInput>
        </Stack>
      </Box>
      )}

      {/* WEIGHT */}
      {  (
        <Box>
          <Typography variant="caption" fontSize={"1rem"}>
            {`The total Weight of all ${state.quantityLabel}`}
          </Typography>
          <Box marginTop={"0.5rem"} />
          <Stack
            spacing={1}
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <FormInput
              name={`items.${packageIndex}.actualWeight`}
              label="Total Weight"
              type="number"
              required
              fullWidth
              onWheel={(event) => event.target.blur()}
              min={0}
            />
            <FormInput
              name={`items.${packageIndex}.actualWeightUOM`}
              label="Unit"
              defaultValue={
                getValues("weightUOM")
                  ? getValues("weightUOM")
                  : weightUnits[0].abreviation
              }
              select
              required
              fullWidth
            >
              {weightUnits.map((unit) => {
                return (
                  <MenuItem
                    key={unit.label}
                    value={unit.abreviation}
                  >{`${unit.abreviation} - ${unit.label}`}</MenuItem>
                );
              })}
            </FormInput>
          </Stack>
        </Box>
      )}

    </Box>
  );
}
