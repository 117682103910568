import { Box, CardHeader } from '@mui/material';
import { SpotmarketEquipmentsField } from '../form/SpotmarketEquipmentsField';
import { StopCitiesFields } from '../form/SpotmarketStopCitiesFields';
import { PickupCitiesFields } from '../form/SpotmarketPickupCitiesFields'

export function FieldsDialogApprove({ locationPickup, locationStop, shipment }) {
    return (
        <>

            <Box display={"flex"} sx={{ width: "100%", mb: 2, gap: 2 }}>
                <Box sx={{ width: "100%", gap: 2, display: "flex", flexDirection: "column" }}>
                    <CardHeader
                        title={"Pickup"}
                        sx={{
                            "&.MuiCardHeader-root": { p: 0 },
                        }}
                    />
                    <PickupCitiesFields location={locationPickup} />
                </Box>
                <Box sx={{ width: "100%", gap: 2, display: "flex", flexDirection: "column" }}>
                    <CardHeader
                        title={"Stop"}
                        sx={{
                            "&.MuiCardHeader-root": { p: 0 },
                        }}
                    />
                    <StopCitiesFields location={locationStop} />
                </Box>
            </Box>
            <SpotmarketEquipmentsField
                multiple={false}
                background={"transparent"}
                equipments={shipment?.metadata?.spotmarket?.equipments}
                filled
            />
        </>
    );
}
