import {
  Autocomplete,
  CircularProgress,
  TextField
} from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSnackbar } from "src/hooks/useSnackbar";
import { useAuth } from "src/hooks/useAuth";
import { ListItemCarriersDetail } from "./SearchItemCarriersDetails";
import { CarrierService } from "src/api/carrier.service";

export function CarriersSearchField({
  name,  
  label = "Carriers",
  placeholder = "Carrier Name",  
}) {
  const { setSnackbarMessage } = useSnackbar();
  const { session } = useAuth();
  const service = new CarrierService(session.token, session.tenant._id);

  const { control, setValue, getValues } = useFormContext();

  const [loading, setIsLoading] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [options, setOptions] = useState([]);

  const abortControllerRef = useRef(null);

  const debounceEffect = debounce((carrier) => {
    if (carrier?.length && carrier != "") {
      const fetchData = async () => {
        setOptions([]);
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        try {
          const validatorBody = {
            name,
            value: carrier,
          };

          const result = await service.search(
            validatorBody,
            abortControllerRef.current?.signal
          );

          // If fetch is successful, update options
          if (result.search && result.search.length > 0) {
            const data = [...result.search];
            setOptions(data);
          }
          setIsLoading(false);
        } catch (err) {
          // Handle fetch errors
          if (err.name === "AbortError") {
            // Fetch was aborted
            console.log("Fetch was aborted.");
          } else {
            setIsLoading(false);

            // Fetch error occurred
            setSnackbarMessage({
              message: err.message,
              severity: "error",
            });
          }
        }
      };
      fetchData();
    }
  }, 1500);

  useEffect(() => {
    if (!openAutocomplete) {
      setOptions([]);
    }
  }, []);

  console.log(getValues("role.carrier"))

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...field}
            freeSolo
            loading={loading}
            open={openAutocomplete}
            autoComplete
            onClose={() => setOpenAutocomplete(false)}
            onOpen={() => setOpenAutocomplete(true)}
            options={options.filter((opt) => typeof opt === "object" && opt.name)}            
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            onChange={(event, value) => {
              setValue("role.carrier", value);               
            }}
            value={getValues("role.carrier")}                 
            filterOptions={(x) => x}
            includeInputInList            
            onInputChange={(event, inputValue) => {              
              setIsLoading(!!inputValue);
              debounceEffect(inputValue);
            }}   
            getOptionLabel={(option) =>
              option[0]?.name ? option[0]?.name : option?.name ? option.name : ""
            }                     
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                variant="outlined"
                placeholder={placeholder}
                fullWidth
                required
                label={label}
                {...error}
                InputProps={{
                  ...params.InputProps,
                  sx: { width: "100%", ...params?.InputProps?.sx },
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}                      
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <ListItemCarriersDetail
                key={option._id}
                customProps={props}
                carrier={option}
              />
            )}
          />
        );
      }}
    />
  );
}
