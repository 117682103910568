export const orderColumns = () => {
  return [
    'PO NUMBER',
    'RELEASE NUMBER',
    'SHIPMENT NUMBER',
    'PO LINE NUMBER',
    'PROMISED',
    'ACTUAL',
    'VENDOR NAME',
    'SHIP TO LOCATION',
    'ADDRESS 1',
    'ADDRESS 2',
    'CITY',
    'STATE',
    'ZIP',
    'EXTENDED COST',
    'ITEM NUMBER',
    'ITEM DESCRIPTION',
    'QUANTITY OPEN',
    'QUANTITY CLOSED',
    'UOM',
  ];
};