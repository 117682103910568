import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Stack,
  Switch,
  TextField
} from "@mui/material";

import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getDayjsDate } from "src/utils/formatTime";

export function SpotmarketRecurringFields(props) {
  const { control, getValues, setValue, watch } = useFormContext();

  const [ isRecurring, setIsRecurring ] = useState(getValues('metadata.spotmarket.recurring.status'));
  const [minEarlyDate] = useState(dayjs());

  function handleSwitchChange(event) {
    const isChecked = event.target.checked;
    setIsRecurring(isChecked);
    setValue("metadata.spotmarket.recurring.status", isChecked);

    if (isChecked) {
      setValue("metadata.spotmarket.recurring.startAt", dayjs());
    } else {
      setValue("metadata.spotmarket.recurring.startAt", null);
      setValue("metadata.spotmarket.recurring.endAt", null)
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        py: "1.5rem",
        px: "1rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title={"Schedule post to DAT & TS"}
        sx={{ "&.MuiCardHeader-root": { p: 0 }, }}
        action={
          <FormControlLabel
            labelPlacement="end"
            label={"Is Recurring?"}
            control={
              <Controller
                name={`metadata.spotmarket.recurring.status`}
                control={control}
                render={({ field: { ref } }) => (
                  <Switch
                    checked={isRecurring}
                    inputRef={ref}
                    onChange={handleSwitchChange}
                  />
                )}
              />
            }
          />
        }
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0, py: "1rem" },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <Stack direction={{ xs: "column", md: "row" }} sx={{gap: 2, display: "flex", width: "100%"}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: 2,
              width: "100%"
            }}
          >
            <Controller
              control={control}
              name={`metadata.spotmarket.recurring.startAt`}
              rules={{ required: isRecurring }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  disabled={!isRecurring}
                  value={getDayjsDate(field.value)}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  timezone="UTC"
                  minDate={minEarlyDate}
                  inputRef={ref}
                  sx={{ width: "100%" }}
                  label="Recurring Initial Date"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Controller
              control={control}
              name={`metadata.spotmarket.recurring.endAt`}
              rules={{ required: isRecurring }}
              render={({ field: { ref, ...field } }) => (
                <DatePicker
                  {...field}
                  disabled={!isRecurring}
                  value={getDayjsDate(field.value)}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  timezone="UTC"
                  minDate={minEarlyDate}
                  inputRef={ref}
                  sx={{ width: "100%" }}
                  label="Recurring End Date"
                />
              )}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}