import * as React from 'react';
import { Button, Card, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { StandardDialog } from 'src/components/dialog/Dialog';
import { ShipmentFormProvider } from '../../context/ShipmentFormContext';
import { useNavigate } from 'react-router-dom';

function DialogMissingInformation({shipmentId, open, onClose, onSave, useFormParams, title, content}) {
    const navigate = useNavigate();

    return (
        <StandardDialog isOpen={open} onClose={onClose}>
            <ShipmentFormProvider
                isSpotmarket
                reset={false}
                onSave={onSave}
                useFormParams={useFormParams}
                sx={{ width: "100%" }}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>{content}</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pb: 2, pr: 3 }}>
                    <Button variant="outlined" onClick={onClose} >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={(() => navigate(`./edit`))}                        
                    >
                        Redirect
                    </Button>
                </DialogActions>
            </ShipmentFormProvider>
        </StandardDialog>
    );
}

export default DialogMissingInformation;