import { Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useAuth } from "src/hooks/useAuth";
import { SpotmarketForm } from "./components/form/SpotmarketForm";
import { useShipmentQueries } from "./hooks/useShipmentQueries";
import { SavingShipmentDialog } from "./components/SavingShipmentDialog";
import { useShipmentSpotMarketQueries } from "./hooks/useShipmentSpotMarketQueries";

export function UpdateSpotmarketShipmentPage({ shipment, isLoading, isDuplication, type }) {
  const { session } = useAuth();

  const {    
    deleteShipment,
  } = useShipmentQueries();

  const {
    updateSpotmarketShipment,
    isUpdateSpotmarketLoading,
    createSpotmarketShipment,
    isCreationSpotmarketLoading,
  } = useShipmentSpotMarketQueries();

  return (
    <Paper
      sx={{
        maxWidth: {
          lg: "1000px",
          xs: "90%",
        },
        margin: "auto",
      }}
      elevation={3}
    >
      <Helmet>
        <title>Update Shipment</title>
      </Helmet>
      <SpotmarketForm
        shipment={shipment}
        type={type}
        loading={isLoading}
        useFormParams={{ defaultValues: shipment }}
        onSave={isDuplication ? createSpotmarketShipment : updateSpotmarketShipment}
        isLoading={isLoading || !shipment}
        isSubmissionLoading={isUpdateSpotmarketLoading || isCreationSpotmarketLoading}
        isBackOffice={session.permissions.IsBackOffice}
        handleRemove={deleteShipment}
      />
      <SavingShipmentDialog
        isOpen={isUpdateSpotmarketLoading || isCreationSpotmarketLoading}
      />
    </Paper>
  );
}
