import { ChevronRightOutlined, LinkOffOutlined, LinkOutlined, RemoveRedEye } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Link, List, ListItem, ListItemButton, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { ShipmentService } from "src/api/shipment.service";
import { ContactButton } from "src/components/contact-button";
import Logo from "src/components/logo";
import { StatusChip } from "src/pages/schedule/components/StatusChip";
import { ShipmentStatusLogHistory } from "../components/details-drawer/StatusLogHistory";

export function ShipmentTrackingInfo() {
  const { primaryReference } = useParams();
  const service = new ShipmentService();

  const {
    mutate: getShipmentTrackingPublicInfo,
    data: shipmentTrackingInfo,
    isLoading: isLoadingShipmentTrackingInfo
  } = useMutation({
    mutationFn: async (primaryReference) => service.getShipmentPublicInfo(primaryReference),
  });


  useEffect(() => {
    getShipmentTrackingPublicInfo(primaryReference);
  }, [])

  return (
    <Container
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: 8,
      }}
    >
      <Stack gap={2}
        sx={{
          width: '80%',
          maxWidth: '630px',
        }}
      >
        <Box
          sx={{
            display: "flex",
            // flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{
                "&.MuiTypography-h1": { fontSize: "1.6rem" },
              }}
              fontWeight={800}
            >
              Shipment Info
            </Typography>
            <Typography
              sx={{
                "&.MuiTypography-h1": { fontSize: "1.4rem" },
              }}
              fontWeight={800}
            >
              {primaryReference}
            </Typography>
          </Box>
          <Box><Logo sx={{ width: "3.5rem" }} /></Box>

        </Box>

        <Paper
          elevation={3}
          sx={{
            width: "100%",
            p: "1.2rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: 4,
          }}
        >
          {
            isLoadingShipmentTrackingInfo || !shipmentTrackingInfo ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  py: 6,
                }}
              >
                <CircularProgress sx={{ m: "auto" }} />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: "column", rowGap: 1 }}>
                {/* Tracking Shipment Info */}
                <Stack
                  flexDirection={{
                    xs: 'column',
                    sm: 'row'
                  }}
                  sx={{
                    gap: 2,
                    justifyContent: 'space-between',
                    alignItems: {
                      xs: 'flex-start',
                      md: 'center'
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                    <Typography>Your shipment is <strong>{shipmentTrackingInfo?.status?.toLocaleLowerCase()}</strong></Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    href={shipmentTrackingInfo.trackingUrl}
                    disabled={!shipmentTrackingInfo.trackingUrl}
                    sx={{
                      borderRadius: '1.5rem', width: {
                        xs: '100%',
                        sm: 'fit-content'
                      }
                    }}
                  >
                    {shipmentTrackingInfo.trackingUrl ?
                      'Tracking Details' : 'Unavailable Tracking'
                    }
                  </Button>
                </Stack>

                <ShipmentStatusLogHistory statusLog={shipmentTrackingInfo.statusLog} />
              </Box>
            )
          }
        </Paper>
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 4,
            p: "1.2rem",
            gap: 1,
          }}
        >
          <Typography variant="body1">Need help?</Typography>
          <ContactButton variant="text" sx={{ px: 2, fontSize: "1rem" }} isSupportOnly />
        </Paper>
      </Stack>
    </Container >
  )
}