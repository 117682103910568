import { Box, Card, CardContent, CardHeader, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";
import { usaStates } from "src/pages/warehouses/constants/usaStates";

export function LocationFields() {
    const {                
        getValues,
        setValue,        
      } = useFormContext();

    const [states] = useState(usaStates);

    console.log(getValues("STATE"))

    useEffect(() => {
        if (
            !states.some(
                (state) =>
                    state.acronym == getValues(`STATE`)
            )
        )
            setValue(`STATE`, "");        
    }, [states]);

    return (
        <Card
            variant="outlined"
            sx={{
                py: "1.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
                pt: "1rem"
            }}
        >
            <CardHeader
                title={"Location Info"}
                sx={{ "&.MuiCardHeader-root": { p: 0 } }}
            />
            <CardContent
                sx={{
                    "&.MuiCardContent-root": { p: 0, py: "1rem" },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem",
                }}
            >
                <Box sx={{ display: "flex", gap: 2 }}>
                    <FormInput
                        name={`SHIP_TO_LOCATION`}
                        label="Ship To Location"
                        required
                        type="text"
                        sx={{ flexGrow: 1 }}
                    />
                </Box>
                <Grid container flex={1} columnGap={1}>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="CITY" label={"City"} fullWidth />
                    </Grid>
                    <Grid itemxs={3} flex={1}>
                        <FormInput
                            name={`STATE`}
                            label="State"
                            select
                            required
                            fullWidth
                        >
                            {states.map((state) => {
                                return (
                                    <MenuItem key={state.acronym} value={state.acronym}>
                                        {state.name}
                                    </MenuItem>
                                );
                            })}
                        </FormInput>
                    </Grid>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="ZIP" label={"ZIP"} fullWidth />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2
                    }}
                >
                    <FormInput sx={{ width: "100%" }} name="ADDRESS1" label={"Address 1"} />
                    <FormInput sx={{ width: "100%" }} name="ADDRESS2" label={"Address 2"} />
                </Box>

            </CardContent>
        </Card>
    )
}