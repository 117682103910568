import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { Roles } from "src/models/roles";

import { ImportIntegrationFile } from "../../ImportIntegrationFile";
import { UploadInput } from "../../UploadInput";

import { useShipmentDetails } from "../../../context/ShipmentDetailsContext";
import { useAuth } from "src/hooks/useAuth";
import { DeleteFileButton } from "../../DeleteFileButton";
import DownloadIcon from '@mui/icons-material/Download';
import { ShipmentUtils } from "src/utils";

export function ShipmentAttachments() {
  const { session } = useAuth();
  const auth = session.permissions;
  const { shipment, setShipment } = useShipmentDetails();  

  const [allowBOLUpload, setAllowBOLUpload] = useState(!shipment.bolFile);
  const [allowPODUpload, setAllowPODUpload] = useState(!shipment.podFile);
  const toggleAllowUpload = (fileType) => {
    if (fileType == "BOL") {
      setAllowBOLUpload(!allowBOLUpload);
    }
    if (fileType == "POD") {
      setAllowPODUpload(!allowPODUpload);
    }
  }; 

  const loadId = shipment.integrations.find(
    (integration) => !!integration?.metadata?.loadId
  )?.metadata?.loadId;
  const hasIntegrationLoadId = !!loadId;

  function updateShipmentFromIntegration(updatedShipment) {
    // VERIFY BOL AND POD
    setAllowBOLUpload(!updatedShipment.bolFile);
    setAllowPODUpload(!updatedShipment.podFile);

    // SET UPDATED SHIPMENT
    setShipment(updatedShipment);
  }

  return (
    <Card
      variant="outlined"
      sx={{
        py: "0.5rem",
        px: "1rem",
        flexGrow: 0.2,
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        title="Attachments"
        sx={{ "&.MuiCardHeader-root": { p: 0 } }}
      />
      <Divider />
      <CardContent sx={{ 
        display: {
          xl: 'flex'
        },
        "&.MuiCardContent-root": { p: 0 },
        flexDirection: {
          xs: 'column',
          // xl: 'row',
        }
      }}>
        <Box
          sx={{
            // display: "flex",
            justifyContent: "space-between",
            py: "0.25rem",
          }}
        >
          <Typography>Tracking URL</Typography>
          <Button
            href={ShipmentUtils.getShipmentPublicInfoHref(shipment)}
            target="_blank"
            rel="noopener"
            sx={{
              display: "flex",
              width: "10rem",
              px: 1,
              gap: 2,
              borderRadius: "0.5rem",
              fontWeight: 800,
              boxShadow: 1
            }}
          >
            Navigate to Tracking
          </Button>
        </Box>
        <Box
          sx={{
            // display: "flex",
            justifyContent: "space-between",
            py: "0.25rem",
          }}
        >
          <Typography>BOL</Typography>
          {shipment.bolFile && !allowBOLUpload ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                href={shipment.bolFile?.url}
                sx={{
                  display: "flex",
                  width: "10rem",
                  px: 1,
                  gap: 2,
                  borderRadius: "0.5rem",
                  fontWeight: 800,
                  boxShadow: 1,
                }}
              >
                Download BOL
              </Button>
              {auth.IsBackOffice && (
                <Tooltip title="Remove current File">
                  <DeleteFileButton
                    shipmentId={shipment._id}
                    fileType={"BOL"}
                    onFileDeleted={() => toggleAllowUpload("BOL")}
                  />
                </Tooltip>
              )}
            </Box>
          ) : auth.IsBackOffice && allowBOLUpload ? (
            <Box sx={{ display: "flex", gap: 1 }}>  
              <UploadInput
                shipmentId={shipment._id}
                fileType={"BOL"}
                uploadCallback={updateShipmentFromIntegration}
              />
              {shipment.mode == "LTL" && hasIntegrationLoadId && (
                <ImportIntegrationFile
                  loadId={loadId}
                  shipmentId={shipment._id}
                  fileType={"BOL"}
                  importCallback={updateShipmentFromIntegration}
                />                
              )}         
            </Box>
          ) : null}
        </Box>
        {/* POD */}
        <Box
          sx={{
            // display: "flex",
            justifyContent: "space-between",
            py: "0.25rem",
          }}
        >
          <Typography>POD</Typography>
          {shipment.podFile && !allowPODUpload ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                href={shipment.podFile?.url}
                sx={{
                  display: "flex",
                  width: "10rem",
                  px: 1,
                  gap: 2,
                  borderRadius: "0.5rem",
                  fontWeight: 800,
                  boxShadow: 1,
                }}
              >
                Download POD
              </Button>
              {auth.IsBackOffice && (
                <Tooltip title="Remove current File">
                  <DeleteFileButton
                    shipmentId={shipment._id}
                    fileType={"POD"}
                    onFileDeleted={() => toggleAllowUpload("POD")}
                  />
                </Tooltip>
              )}
            </Box>
          ) : [Roles.Administrator, Roles.System, Roles.ShipmentManager].some(
              (auth) => auth === session.user.role.name
            ) && allowPODUpload ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <UploadInput
                shipmentId={shipment._id}
                fileType={"POD"}
                uploadCallback={updateShipmentFromIntegration}
              />
              {shipment.mode == "LTL" && hasIntegrationLoadId && (
                <ImportIntegrationFile
                  loadId={loadId}
                  shipmentId={shipment._id}
                  fileType={"POD"}
                />
              )}
            </Box>
          ) : null}
        </Box>        
        {shipment.attachment &&
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: "0.25rem",
          }}
        >
          <Typography>Commercial Invoice</Typography>          
            <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              href={shipment?.attachment?.url}
              sx={{
                display: "flex",
                width: "10rem",
                px: 1,
                gap: 1,
                borderRadius: "0.5rem",
                fontWeight: 800,
                boxShadow: 1,
              }}
            >
            <DownloadIcon></DownloadIcon>
              Download
            </Button>            
          </Box>
        </Box>  
      }      
      </CardContent>
    </Card>
  );
}
