import { createContext, useContext, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { formatDateString } from "src/utils/formatTime";
import { useShipmentQueries } from "../hooks/useShipmentQueries";
import { useShipmentSpotMarketQueries } from "../hooks/useShipmentSpotMarketQueries";

const ShipmentDetailsContext = createContext();

export function ShipmentDetailsProvider(props) {
  const { session } = useAuth();
  const isBackofficeUser = session.permissions.IsBackOffice;
  const isLTLManager = session.permissions.IsLTLManager;
  const isAllowedToBook = isBackofficeUser || isLTLManager || session.actions.BookShipments;

  const [shipment, setShipment] = useState(props.shipment);
  const [quotations, setCarrierQuotations] = useState([]);

  const [askForPending, setAskForPending] = useState(false);
  const [missingInformations, setMissingInformations] = useState(false);
  const [invalidOrders, setInvalidOrders] = useState([]);

  const [selectedQuotation, setSelectedQuotation] = useState();

  const {
    confirmShipment: confirmShipmentRequest,
    getCarrierQuotations: getCarrierQuotationsRequest,
    isConfirmationLoading,
    resetShipmentStatus: resetShipmentStatusRequest,
    isQuotationLoading,
    deleteShipment,
    isDeletionLoading,
    isResettingShipmentLoading,
    validateShipment: validateShipmentRequest,
    isValidateLoading,

    getShipmentQuotations: getShipmentQuotationsRequest,
    isShipmentQuotationLoading,
    shipmentQuotations,

    pendingShipment: pendingShipmentRequest,
    setShipmentAsPendingAndWaiting: pendingAndWaitingShipmentRequest,
    isPendingLoading,
    approveShipment: approveShipmentRequest,
    isApprovingShipmentLoading,
    isDenyingShipmentLoading,
    denyShipment: denyShipmentRequest,
    uncombineShipments: uncombineShipmentRequest,

    isUncombining,

    candidateCarrier,
    isCandidateLoading,    
  } = useShipmentQueries();

  const {
    updateSpotmarket,
    isUpdateSpotLoading,
    
    consolidateShipment: consolidateShipmentRequest,
    isConsolidateLoading,
  } = useShipmentSpotMarketQueries();

  const confirmShipment = (id, quotationIndex = null, appointmentTimePickup = null, appointmentTimeStop = null, bypass = false, shouldSendToSpotMarket = false) => {
    confirmShipmentRequest({
      shipmentId: shipment._id,
      quotationIndex,
      appointmentTimePickup,
      appointmentTimeStop,
      bypass,
      shouldSendToSpotMarket,
      callbackConfirm: setShipmentConfirmed,
      callbackAskForPending: handleAskForPending,
      callbackMissingInformation: handleMissingInformation,
    });
  };

  const getCarrierQuotations = async (bypass = false) => {
    getCarrierQuotationsRequest({
      shipmentId: shipment._id,
      bypass,
      callbackSetQuotations: setCarrierQuotations,
      callbackAskForPending: handleAskForPending,
    });
  };

  const getShipmentQuotations = async () => {
    getShipmentQuotationsRequest({
      shipmentId: shipment._id,
      callback: setCarrierQuotations,
    });
  };

  const consolidateShipment = async () => {
    consolidateShipmentRequest({
      shipmentId: shipment._id,
      callback: setShipmentConsolidated,
    });
  };

  const resetShipmentStatus = async () => {
    resetShipmentStatusRequest({
      shipmentId: shipment._id,
    });
  };

  const validateShipment = async () => {
    validateShipmentRequest({
      shipmentId: shipment._id,
    });
  };

  const pendingShipment = async ({ shipmentId, rate }) => {
    pendingShipmentRequest({
      shipmentId: shipmentId,
      rate: rate,
      callback: setShipmentPending,
    });
  };

  const approveShipment = async () => {
    approveShipmentRequest({
      shipmentId: shipment._id,
      callback: setApprovedOrDeniedShipment,
    });
  };

  const denyShipment = async () => {
    denyShipmentRequest({
      shipmentId: shipment._id,
      callback: setApprovedOrDeniedShipment,
    });
  };

  const setShipmentAsPendingAndWaiting = async () => {
    pendingAndWaitingShipmentRequest({
      shipmentId: shipment._id,
      rate: null,
      callback: setShipmentPending,
    });
  };

  function setShipmentConfirmed() {
    setShipment((currentShipment) => {
      return { ...currentShipment, integrated: true };
    });
  }

  function setShipmentConsolidated(data) {
    setShipment(data);
  }

  const handleAskForPending = (invalidOrders) => {
    setAskForPending(true);
    setInvalidOrders(invalidOrders)
  }

  const handleMissingInformation = () => {
    setMissingInformations(true);    
  }

  function setShipmentPending() {
    setShipment((currentShipment) => {
      return {
        ...currentShipment,
        metadata: {
          ...currentShipment.metadata,
          pending: true,
        },
      };
    });
  }

  function setApprovedOrDeniedShipment() {
    setShipment((currentShipment) => {
      return {
        ...currentShipment,
        metadata: {
          ...currentShipment.metadata,
          pending: false,
        },
      };
    });
  }

  const formattedTrackingDetails = () => {
    if (!shipment.tracking) {
      return [];
    }

    return shipment.tracking?.TrackingStatuses.filter(
      (i) => i.Type.toLowerCase() === "web"
    )
      .sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime))
      .map((item) => ({
        date: formatDateString(item.DateTime),
        location: item.City && item.State && `${item.City}, ${item.State}`,
        message: item.CarrierMessage,
      }));
  };

  const addCandidateCarrier = (rate = null, mc = null, eta = null, emptyOn = null, phone = null, notes = null) => {
    console.log({ rate, mc, eta, emptyOn, phone, notes })
    candidateCarrier({
      rate,
      mc,
      eta,
      emptyOn,
      phone,
      notes,
      shipmentId: shipment._id,
    });
  };

  return (
    <ShipmentDetailsContext.Provider
      value={{
        shipment,
        setShipment,
        tracking: formattedTrackingDetails(),
        resetShipmentStatus,
        isResettingShipmentLoading,
        quotations: quotations,
        setQuotations: setCarrierQuotations,

        selectedQuotation,
        setSelectedQuotation,

        confirmShipment,
        getCarrierQuotations,

        getShipmentQuotations,
        isShipmentQuotationLoading,
        shipmentQuotations,

        setShipmentConfirmed,
        isConfirmationLoading,
        isQuotationLoading,
        isBackofficeUser,
        isLTLManager,
        isAllowedToBook,

        deleteShipment,
        isDeletionLoading,

        validateShipment,
        isValidateLoading,

        consolidateShipment,
        isConsolidateLoading,

        pendingShipment,
        setShipmentAsPendingAndWaiting,
        setShipmentPending,
        isPendingLoading,
        approveShipment,
        isApprovingShipmentLoading,
        denyShipment,
        isDenyingShipmentLoading,
        setAskForPending,
        askForPending,
        invalidOrders,

        missingInformations, 
        setMissingInformations,

        uncombine: uncombineShipmentRequest,
        isUncombining,

        addCandidateCarrier,
        isCandidateLoading,

        updateSpotmarket,
        isUpdateSpotLoading
      }}
    >
      {props.children}
    </ShipmentDetailsContext.Provider>
  );
}

export const useShipmentDetails = () => {
  const context = useContext(ShipmentDetailsContext);

  if (!context) {
    throw new Error(
      "useShipmentDetails must be used within a ShipmentDetailsProvider"
    );
  }

  return context;
};