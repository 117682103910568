import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { FormInput } from "src/components/form/FormInput";
import { SpotmarketCoreFields } from "./SpotmarketCoreFields";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useDeletion } from "src/pages/shipments/hooks/useDeletion";
import { SpotmarketPackagesFieldsWrapper } from "./PackageFields/SpotmarketPackagesWrapper";
import { useTenant } from "src/hooks/useTenant";
import { useEffect, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { SpotmarketPORequiredPackageForm } from "./SpotmarketPORequiredPackageForm";
import { SpotmarketRateFields } from "./SpotmarketRateFields";
import { SpotmarketEquipmentsField } from "./SpotmarketEquipmentsField";
import { SpotmarketFormProvider } from "../../context/SpotmarketFormContext";
import { useCustomerQueries } from "src/pages/customers/hooks/useCustomerQueries";
import { SpotmarketRecurringFields } from "./SpotmarketRecurringFields";

export function SpotmarketForm(props) {
  
  const { session } = useAuth();
  const { shipmentId } = useParams();

  const { 
    getCustomers,
    customers,
  } = useCustomerQueries();

  useEffect(() => {
    getCustomers();
  }, [])
  
  const isBackOffice = session.permissions.IsBackOffice;
  const isBookAllowed = session.actions.BookShipments;  

  const type = props.type ? props.type : "create";
  const titleType = type[0].toUpperCase() + type.slice(1);
  const title = type == "create" ? "Create New Shipment" : `${titleType} Shipment`;

  const { handleOpenClickDeletionDialog, DeletionDialog } =
    useDeletion(deleteShipment);
  const handleRemove = props.handleRemove ? props.handleRemove : null;
  const allowRemove = isBackOffice && handleRemove ? true : false;

  const { tenants, tenant } = useTenant();

  const navigate = useNavigate();
  const [isInitialized, setInitialized] = useState(props.initialized ?? true);
  const [ invalidPONumber, setInvalidPoNumber] = useState(false);

  function deleteShipment() {
    props.handleRemove(shipmentId);
    navigate("/shipments");
  }  

  if (props.isLoading) {
    return (
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={{ maxWidth: "100%", height: "80vh", opacity: 0.6 }}
      ></Skeleton>
    );
  }

  return (
    <Box sx={{ maxWidth: "80%", margin: "auto", py: 3 }}>
      <SpotmarketFormProvider
        isSpotmarket
        onSave={props.onSave}
        reset={props.reset ? props.reset : false}
        useFormParams={props.useFormParams}
        onError={(message) => { if (message === "PONumberError") setInvalidPoNumber(true)}}
      >
        <Box
          sx={{
            width: "100%",
            display: {
              xs: "block",
              sm: "flex",
            },
            alignItems: "center",
            justifyContent: "space-between",
            mb: "2rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
            <Typography variant="h4" component="h1">
              {title}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
            <FormInput
              name="tenant"
              label="Tenant"
              required
              select
              sx={{ width: "8rem" }}
              disabled
              defaultValue={tenant._id}
            >
              {tenants?.map((ten) => (
                <MenuItem key={ten._id} value={ten._id}>
                  {ten.code}-{ten.title}
                </MenuItem>
              ))}
            </FormInput>
            <FormInput
              name="mode"
              label="Mode"
              required
              select
              disabled={!isBackOffice && !isBookAllowed}
              sx={{ width: "8rem" }}
            >
              <MenuItem value={"TL"}>TL</MenuItem>
              <MenuItem value={"PAR"}>PAR</MenuItem>
            </FormInput>
          </Box>
        </Box>

        <Stack spacing={3}>

          {/* MAIN SHIPMENT FIELDS */}
          <SpotmarketCoreFields />

          { /* EQUIPMENTS */  }
          <SpotmarketEquipmentsField />

          {/* ITEMS */}
          <SpotmarketPackagesFieldsWrapper />

          {/* RECURRING */}
          <SpotmarketRecurringFields />

          {/* RATES AND OTHER DETAILS */}
          <SpotmarketRateFields customers={customers} />

          {/* SPECIAL INSTRUCTIONS */}
          <Card
            elevation={0}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
            }}
          >
            <CardHeader
              title={"Special Instructions"}
              // subheader={locationLabel}
              sx={{
                "&.MuiCardHeader-root": { p: 0 },
              }}
            />
            <CardContent
              sx={{
                "&.MuiCardContent-root": { p: 0 },
              }}
            >
              <FormInput
                name="specialInstructions"
                placeholder="Fill with extra information relevant to the shipment"
                fullWidth
                multiline
                rows={4}
              />
            </CardContent>
          </Card>

        </Stack>

        <Stack gap={1}>
          <LoadingButton
            variant="contained"
            fullWidth
            type="submit"
            loading={
              props.isSubmissionLoading ? props.isSubmissionLoading : false
            }
            sx={{ py: "0.8rem", mt: "2rem" }}
          >
            {type} Shipment
          </LoadingButton>
          {allowRemove && (
            <LoadingButton
              variant="text"
              fullWidth
              type="button"
              // loading={isLoading}
              size="small"
              color="error"
              onClick={handleOpenClickDeletionDialog}
              sx={{
                py: "0.8rem",
                lineHeight: 1.5,
              }}
              startIcon={<DeleteOutlineOutlined />}
            >
              Delete Shipment
            </LoadingButton>
          )}
        </Stack>
        <SpotmarketPORequiredPackageForm
          onClose={() => setInvalidPoNumber(false)}
          isOpen={invalidPONumber}
          onDone={() => setInvalidPoNumber(false)}
        />
      </SpotmarketFormProvider>
      {DeletionDialog}
    </Box>
  );
}
