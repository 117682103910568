import { Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useAuth } from "src/hooks/useAuth";

import { ShipmentForm } from "./components/form/ShipmentForm";
import { useShipmentQueries } from "./hooks/useShipmentQueries";
import { SavingShipmentDialog } from "./components/SavingShipmentDialog";
import { useShipmentSpotMarketQueries } from "./hooks/useShipmentSpotMarketQueries";

export function UpdateRegularShipmentPage({ shipment, isLoading, isDuplication, type }) {
  const { session } = useAuth();
  const isSpotMarket = session.tenant?.modules?.spotmarket  

  const {
    updateShipment,
    isUpdateLoading,
    createShipment,
    isCreationLoading,
    deleteShipment,            
  } = useShipmentQueries();

  return (
    <Paper
      sx={{
        maxWidth: {
          lg: "1000px",
          xs: "90%",
        },
        margin: "auto",
      }}
      elevation={3}
    >
      <Helmet>
        <title>Update Shipment</title>
      </Helmet>
      <ShipmentForm
        type={type}
        loading={isLoading}
        useFormParams={{ defaultValues: shipment }}
        onSave={(isDuplication ? createShipment : updateShipment)}
        isLoading={isLoading || !shipment}
        isSubmissionLoading={isUpdateLoading || isCreationLoading}
        isBackOffice={session.permissions.IsBackOffice}
        handleRemove={deleteShipment}
      />
      <SavingShipmentDialog
        isOpen={isUpdateLoading || isCreationLoading}
      />
    </Paper>
  );
}
