import { ArrowDropDownCircle, Assessment, CalendarMonth, CorporateFareOutlined, EmojiTransportation, History, LocationOn, PlagiarismOutlined, QuestionMarkOutlined } from "@mui/icons-material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HouseIcom from "@mui/icons-material/House";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import PeopleIcon from "@mui/icons-material/People";

const navConfig = [
  {
    title: "Shipments",    
    icon: <ArrowDropDownCircle />,
    showWhen: (session) => {
      const authorizedRoles = [
        "IsAdmin",
        "IsBackOffice",
        "IsWarehouseManager",
        "IsSupplierManager",
        "IsCustomerManager",
        "IsLTLManager",
      ];

      if (session.tenant.modules.shipments) {
        return authorizedRoles.some((role) => session.permissions[role]);
      }

      return false;
    },
    children: [
      {
        title: "Dashboard",
        path: "/shipments",
        icon: <LocalShippingOutlinedIcon />,
        showWhen: (session) => {
          const authorizedRoles = [
            "IsAdmin",
            "IsBackOffice",
            "IsWarehouseManager",
            "IsSupplierManager",
            "IsCustomerManager",
            "IsLTLManager",
          ];

          if (session.tenant.modules.shipments) {
            return authorizedRoles.some((role) => session.permissions[role]);
          }

          return false;
        }
      },
      {
        title: (session) => 
          session.tenant.modules?.spotmarket ? "Spotmarket" : "Pending Shipments",
        path: "/pending-shipments",
        icon: <ErrorOutlineIcon />,
        showWhen: (session) => {
          const authorizedAction = "ApproveDenyShipments";

          if (session.tenant.modules.shipments && session.actions?.[authorizedAction]) {
            return true;
          }

          return false;
        },
      },
      {
        title: "Closed Shipments",
        path: "/closed-shipments",
        icon: <History />,
        showWhen: (session) => {
          const authorizedRoles = ["IsBackOffice", "IsLTLManager"];

          if (session.tenant.modules.shipments) {
            return authorizedRoles.some((role) => session.permissions[role]);
          }

          return false;
        },
      },
    ],
  },
  {
    title: "Warehouses",
    path: "/warehouses",
    icon: <HouseIcom />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice", "IsWarehouseManager" ]

      if(session.tenant.modules.warehouse) {
        return authorizedRoles.some((role) => session.permissions[role]);
      }
      return false;
    },
  },
  {
    title: "Locations",
    path: "/locations",
    icon: <LocationOn />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice"];

      return authorizedRoles.some((role) => session.permissions[role]);
    }
  },
  {
    title: "Carriers",
    path: "/carriers",
    icon: <EmojiTransportation />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice"];

      return authorizedRoles.some((role) => session.permissions[role]);
    }
  },
  {
    title: "Orders",
    path: "/orders",
    icon: <AssignmentIcon />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice" , "IsSupplierManager"];     
      const isThirdParty = session.user?.metadata?.thirdParty; 
      
      return !isThirdParty && authorizedRoles.some((role) => session.permissions[role]);
    }
  },
  {
    title: "Reports",
    path: "/reports",
    icon: <Assessment />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice", "IsWarehouseManager", "IsCustomerManager" ]

      // if(session.tenant.modules.reports) {
        return authorizedRoles.some((role) => session.permissions[role]);
      // }
      return false;
    },
  },
  {
    title: "Schedule",
    path: "/schedule/by-date",
    icon: <CalendarMonth />,
    showWhen: (session) => {
      const authorizedRoles = [ 'IsBackOffice', 'IsWarehouseManager', 'IsContractor' ];

      if(session.tenant.modules.schedule) {
        return authorizedRoles.some((role) => session.permissions[role]);
      }

      return false;
    }
  },
  {
    title: "Users",
    path: "/users",
    icon: <PeopleIcon />,
  },
  {
    title: "Vendors",
    path: "/vendors",
    icon: <EngineeringIcon />,
    showWhen: (session) => {
      const authorizedRoles = [ 'IsBackOffice' ];
      
      if(session.tenant.modules.schedule) {
        return authorizedRoles.some((role) => session.permissions[role]);
      }

      return false;
    }
  },
  {
    title: "Tenants",
    path: "/tenants",
    icon: <CorporateFareOutlined />,
    showWhen: (session) => {
      const authorizedRoles = [ 'IsBackOffice' ];
      
      return authorizedRoles.some((role) => session.permissions[role]);
    }
  },
  {
    title: "Audits",
    path: "/audits",
    icon: <PlagiarismOutlined />,
    showWhen: (session) => {
      const authorizedRoles = [ "IsBackOffice"]

      return authorizedRoles.some((role) => session.permissions[role]);      
    },
  },
  {
    title: "Help Center",
    path: "/support",
    icon: <QuestionMarkOutlined />,
  },
];

export default navConfig;
