import React, { useEffect, useMemo, useState } from 'react';
import { Button, Box, Card, Container, Grid, Typography } from '@mui/material';
import { useShipmentQueries } from './hooks/useShipmentQueries';
import { isEmpty, isEqual } from 'lodash';
import { dailySummaryColumns } from './constants/shipmentColumns';
import { spotmarketShipmentColumns } from "./constants/spotmarketShipmentsColumns";
import { ShipmentsDataGrid } from './components/ShipmentsDataGrid';
import { useNavigate, useParams } from 'react-router-dom';
import { Check, DoDisturb } from '@mui/icons-material';
import DialogConfirmForShipments from './components/details-drawer/DialogConfirmForShipments';
import { useSystem } from 'src/hooks/useSystem';
import { useTenant } from 'src/hooks/useTenant';
import DialogConfirmForSpotMarket from './components/details-drawer/DialogConfirmForSpotMarket';
import { useShipmentSpotMarketQueries } from './hooks/useShipmentSpotMarketQueries';

const initialPageState = {
    selectedCalendarDate: new Date().setHours(0, 0, 0, 0),
    isCalendarVisible: false,
}

export function PendingShipments() {
    const { socket } = useSystem();
    const [pageState, setPageState] = useState(initialPageState);
    const { tenant } = useTenant();
    const { shipmentId } = useParams();
    const [cachedShipments, setShipmentsCache] = useState([])

    const {
        getPendingShipments,
        isLoadingPendingShipments,
        isRefetchingPendingShipments,
        approveShipment,
        approveAllShipments,
        denyShipment,
        denyAllShipments,
        pendingShipments: shipments,
        onNewPendingShipment,
        onUpdateShipments,
        onApproveDenyShipment,        
    } = useShipmentQueries();

    const {
        updateSpotmarket,
        isUpdateSpotLoading,
        exportDAT
    } = useShipmentSpotMarketQueries();

    const [openDialogToApprove, setOpenDialogToApprove] = useState(false);
    const [openDialogToDeny, setOpenDialogToDeny] = useState(false);

    const [openDialogToApproveAll, setOpenDialogToApproveAll] = useState(false);
    const [openDialogToDenyAll, setOpenDialogToDenyAll] = useState(false);

    useEffect(() => { 
        getPendingShipments() 
    }, [])

    useEffect(() => {
        if (shipments) 
            setShipmentsCache(shipments)
    }, [shipments])

    useEffect(() => {
        if (!socket) return;
    
        const stopNewPending = onNewPendingShipment((shipment) => {
            setShipmentsCache((prev) => [...prev, shipment]);
        });
    
        const stopUpdateShipments = onUpdateShipments((updatedShipments) => {
            setShipmentsCache((prevShipments) =>
                prevShipments.map((shipment) => {
                    const updated = updatedShipments.find((up) => up._id === shipment._id);
                    return updated ? { ...shipment, ...updated } : shipment;
                })
            );
        });
    
        return () => {
            stopNewPending();
            stopUpdateShipments();
        };
    }, [socket, onNewPendingShipment, onUpdateShipments]);

    const handleExportSpotmarket = (mode) => {
        exportDAT({ shipmentsIds: selectedRowState?.shipmentIds, mode: mode });
    }

    useEffect(() => {
        if (!socket) return;

        const stopper = onApproveDenyShipment((shipmentIds) => {
            setShipmentsCache(prev =>
                prev.filter(sh => !sh._id.includes(shipmentIds))
            );
        });

        return () => stopper();
    }, [onApproveDenyShipment]);

    const [shipmentPending, setShipmentPending] = useState(null);
    const [openConfirmSpotMarket, setOpenConfirmSpotMarket] = useState(false);

    const handleApproveShipment = (e, shipment) => {
        e.stopPropagation();
        if (!tenant.modules?.spotmarket) {
            setOpenDialogToApprove(true)
        } else {
            setOpenConfirmSpotMarket(true)
        }
        setShipmentPending(shipment)
    }

    const handleDenyShipment = (e, shipment) => {
        e.stopPropagation();
        setOpenDialogToDeny(true)
        setShipmentPending(shipment)
    }

    const approveShipmentId = (e) => {
        approveShipment({ shipmentId: shipmentPending._id }, {
            onSuccess: () => {
                setShipmentsCache(prevShipments =>
                    prevShipments.filter(sh => sh._id !== shipmentPending._id)
                );
            }
        });
    }

    const denyShipmentId = (e) => {
        denyShipment({ shipmentId: shipmentPending._id }, {
            onSuccess: () => {
                setShipmentsCache(prevShipments =>
                    prevShipments.filter(sh => sh._id !== shipmentPending._id)
                );
            }
        });
    }

    const handleApproveAllShipments = (e) => {
        setOpenDialogToApproveAll(true)
        setShipmentPending(null)
    };

    const handleDenyAllShipments = (e) => {
        setOpenDialogToDenyAll(true)
        setShipmentPending(null)
    };

    const [selectedRowState, setRowSelected] = useState({
        shipmentIds: [],
        shipments: [],
    });

    const onRowSelected = (ids) => {
        const shipments = !ids || !ids.length ? [] : filteredData.filter(shipment => ids.includes(shipment._id));
        setRowSelected({
            shipmentIds: ids,
            shipments,
        });
    }

    let filteredData = useMemo(() => {
        let shipments = cachedShipments;
        if (!Array.isArray(shipments)) return [];

        if (pageState.isCalendarVisible && pageState.selectedCalendarDate) {
            return shipments.filter((shipment) => {
                const deliveryDate = new Date(
                    shipment.stops[0].targetDeliveryLate
                ).setHours(0, 0, 0, 0);

                const shipDate = new Date(
                    shipment.pickups[0].targetShipEarly
                ).setHours(0, 0, 0, 0);

                const currentDate = pageState.selectedCalendarDate;

                return isEqual(deliveryDate, currentDate) || isEqual(shipDate, currentDate);
            });
        }
        return shipments;

    }, [cachedShipments]);

    let columns = null
    if (!tenant.modules?.spotmarket)
        columns = dailySummaryColumns(null, tenant, true, handleApproveShipment, handleDenyShipment)
    else
        columns = spotmarketShipmentColumns(null, tenant, true, handleApproveShipment, handleDenyShipment)

    const navigate = useNavigate();

    const confirmSpotMarket = (shipmentId) => {
        navigate(`/shipments/${shipmentId}/spotmarket/approve`);
    }

    return <Container maxWidth="false">
        <Typography
            variant="h4"
            sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
        >
            { tenant.modules?.spotmarket ? 'Spotmarket' : 'Pending Shipments'}
        </Typography>
        <Box sx={{ my: 4, display: { md: "none" } }} />
        <Grid container item xs={12}>
            <Card sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'justify-between' }}>
                    <Box>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={handleApproveAllShipments}
                            sx={{ mr: 2 }}>
                            <Check sx={{ marginRight: '4px', fontSize: '16px' }} />
                            Approve All
                        </Button>
                        <Button
                            sx={{ mr: 2 }}
                            variant="outlined"
                            size="small"
                            onClick={handleDenyAllShipments}>
                            <DoDisturb sx={{ marginRight: '4px', fontSize: '16px' }} />
                            Deny All
                        </Button>
                    </Box>
                    <Box>
                        { /* EXPORT DAT & TS */}
                        {tenant.modules?.spotmarket && selectedRowState?.shipmentIds?.length > 0 &&
                            <>
                                <Button
                                    variant={pageState.selectedTab == "combined" ? "outlined" : "text"}
                                    onClick={() => handleExportSpotmarket('DAT')}
                                    sx={{ maxHeight: 'fit-content' }}
                                > Export DAT</Button>
                                <Button
                                    variant={pageState.selectedTab == "combined" ? "outlined" : "text"}
                                    onClick={() => handleExportSpotmarket('TS')}
                                    sx={{ maxHeight: 'fit-content' }}
                                > Export TS</Button>
                            </>}
                    </Box>
                </Box>
                <section className="dataTable">
                    <ShipmentsDataGrid
                        shipments={filteredData}
                        isLoadingData={isLoadingPendingShipments || isRefetchingPendingShipments}
                        columns={columns}
                        selectedItemId={shipmentId}
                        disableMultipleRowSelection={tenant.modules?.spotmarket ? true : false}
                        getTreeDataPath={(row) => row.hierarchy || []}
                        defaultGroupingExpansionDepth={1}
                        modelKey={tenant.modules?.spotmarket ? "spotmarketPendingShipments" : "pendingShipments"}
                        onRowSelectionModelChange={(selectedItemsIds, details) => onRowSelected(selectedItemsIds)}
                        isInvalidShipment
                    />
                </section>
            </Card>
        </Grid>

        <DialogConfirmForShipments
            title={"Approve Shipment"}
            text={"Are you sure you want to approve this shipment?"}
            onConfirm={(e) => {
                setOpenDialogToApprove(false);
                approveShipmentId(e);
            }}
            onClose={() => { setOpenDialogToApprove(false) }}
            open={openDialogToApprove} />

        <DialogConfirmForShipments
            title={"Deny Shipment"}
            text={"Are you sure you want to deny and archive this shipment?"}
            onConfirm={(e) => {
                setOpenDialogToDeny(false);
                denyShipmentId(e);
            }}
            onClose={() => { setOpenDialogToDeny(false) }}
            open={openDialogToDeny} />

        <DialogConfirmForShipments
            title={"Approve All Shipments"}
            text={"Are you sure you want to approve all the shipments?"}
            onConfirm={(e) => {
                setOpenDialogToApproveAll(false);
                approveAllShipments()
            }}
            onClose={() => { setOpenDialogToApproveAll(false) }}
            open={openDialogToApproveAll} />

        <DialogConfirmForShipments
            title={"Deny All Shipments"}
            text={"Are you sure you want to deny all the shipment?"}
            onConfirm={(e) => {
                setOpenDialogToDenyAll(false);
                denyAllShipments();
            }}
            onClose={() => { setOpenDialogToDenyAll(false) }}
            open={openDialogToDenyAll} />

        { tenant.modules?.spotmarket && (
            <DialogConfirmForSpotMarket
                open={openConfirmSpotMarket}
                title={"Fulfill shipment informations"}
                content={"You will be redirected to shipment form in order to fill the missing informations. "}
                shipment={shipmentPending}
                useFormParams={{ defaultValues: shipmentPending }}      
                onSave={updateSpotmarket}
                isLoading={isUpdateSpotLoading}
                onClose={() => {
                    setOpenConfirmSpotMarket(false);
                }}
            />
        )}

    </Container>
}
