import { Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { OrderForm } from "./components/OrderForm";
import { useOrderQueries } from "./hooks/useOrderQueries";
import { OrderFormProvider } from "./context/OrderFromContext";

export function CreateOrderPage() {
  const {
    create,
    isCreationLoading,
  } = useOrderQueries();

  return (
    <Paper
      sx={{
        maxWidth: {
          lg: "1000px",
          xs: "90%",
        },
        margin: "auto",
      }}
      elevation={3}
    >
      <Helmet>
        <title>Create Order</title>
      </Helmet>
      <OrderForm
        type="Create"
        onSave={create}
        isSubmissionLoading={isCreationLoading}
      />
    </Paper>
  )
}