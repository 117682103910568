import config from "src/config";
import {
  BaseService
} from "./base.service";
import { method } from "lodash";

export class CarrierService extends BaseService {
  baseUrl = `${config.apiUrl}/carriers`;

  getCarriers = async (filter) => {
    const uri = new URL(`${this.baseUrl}`);
    if (filter) uri.searchParams.append("filter", filter);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  };

  getCarrier = async (carrierId) => {
    const uri = new URL(`${this.baseUrl}/${carrierId}`);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  };

  createCarrier = async (data) => {
    const uri = new URL(this.baseUrl);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify(data)
      })
    );

    const carrierResponseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(carrierResponseJson);
      throw new Error(`Error while creating carrier ${errorMessage}`);
    }
    return carrierResponseJson;
  }

  deleteCarrier = async (carrierId) => {
    const uri = new URL(this.baseUrl + '/' + carrierId);

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'DELETE',
      })
    );

    return await response.json();
  }

  updateCarrier = async (carrierId, data) => {
    const uri = new URL(config.apiUrl + `/carrier/${carrierId}`);

    const response = await fetch(
      uri.toString(),
      this.header({
        body: JSON.stringify(data),
        method: "PUT"
      })
    );

    return await response.json();
  };

  getCarriersPaginated = async (params) => {
    const uri = new URL(`${this.baseUrl}/paginated`);
    let signal = null;
    if (params) {
      signal = params.signal;
      delete params.signal;

      uri.searchParams.append("limit", params.limit);
      uri.searchParams.append("page", params.page);
      uri.searchParams.append("search", params.search);
    };
    
    const response = await fetch(uri.toString(), this.header({
      method: 'POST',
      body: JSON.stringify(params.filters),
      signal,
    }));
    
    return await response.json();
  };

  async search(searchParam, signal) {
    const uri = new URL(`${this.baseUrl}/search`);

    const response = await fetch(uri.toString(), this.header({
      method: "POST",
      body: JSON.stringify(searchParam),
      signal,
    }));

    if (!response.ok && response.status !== 404)
      throw new Error(await response.json().then((body) => body.message));

    return await response.json();
  }
}


function getErrorMessage(error) {
  let errorMessage;

  if (error.message)
    errorMessage = error.message;
  if (error.msg)
    errorMessage = error.msg;
  if (error.messages) {
    if (Array.isArray(error.messages)) {
      errorMessage = error.errors.join(', ');
    }
  }
  if (error.errors && Array.isArray(error.errors)) {
    errorMessage = error.errors.map(error => error.msg).join(', ');
  }

  return errorMessage;
}