import { LoadingButton } from "@mui/lab";
import {
  Box,  
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import { OrderFormProvider } from "../context/OrderFromContext";
import { LocationFields } from "./LocationFields";
import { BasicInfos } from "./BasicInfos";
import { ItemsFields } from "./ItemsFields";

export function OrderForm({ isLoading, onSave, reset, useFormParams, type, isSubmissionLoading }) {
  const titleType = type[0].toUpperCase() + type.slice(1);
  const title = type == "create" ? "Create New Order" : `${titleType} Order`;

  if (isLoading) {
    return (
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={{ maxWidth: "100%", height: "80vh", opacity: 0.6 }}
      ></Skeleton>
    );
  }

  return (
    <Box sx={{ maxWidth: "80%", margin: "auto", py: 3 }}>
      <OrderFormProvider
        onSave={onSave}
        reset={reset ? reset : false}
        useFormParams={useFormParams}
      >
        <Box
          sx={{
            width: "100%",
            display: {
              xs: "block",
              sm: "flex",
            },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
            <Typography variant="h4" component="h1">
              {title}
            </Typography>
          </Box>
        </Box>
        <Stack gap={2}>
          <BasicInfos />
          <LocationFields />
          <ItemsFields UOM={useFormParams?.defaultValue?.UOM}/>
        </Stack>

        <Stack gap={1}>
          <LoadingButton
            variant="contained"
            fullWidth
            type="submit"
            loading={
              isSubmissionLoading ? isSubmissionLoading : false
            }
            sx={{ py: "0.8rem", mt: "2rem" }}
          >
            {type} Order
          </LoadingButton>
        </Stack>
      </OrderFormProvider>
    </Box>
  );
}