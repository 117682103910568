import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  List,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";

import { Controller, useFormContext } from "react-hook-form";
import { PackageDialog } from "../../PackageDialog";
import { PackageListItem } from "./PackageListItem";
import { PropaneSharp } from "@mui/icons-material";

const InitialDialogState = {
  isOpen: false,
  action: 'none',
  indexToEdit: -1,
}

export function PackagesFieldsWrapper({transparent}) {
  const {
    items: { remove, update },
    form: { control, getValues }
  } = useShipmentFormContext();

  const { watch } = useFormContext();
  const items = watch("items");

  const [dialogState, setDialogState] = useState(InitialDialogState);

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          py: "1.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
          background: transparent
        }}
      >
        <CardHeader
          title="Packages"
          action={
            <Button
              type="button"
              size="small"
              onClick={() => setDialogState({
                isOpen: true,
                action: 'create',
                indexToEdit: items?.length ?? 0,
              })}
              sx={{ minWidth: "8rem", height: "2.5rem" }}
            >
              Add Package
            </Button>
          }
          sx={{
            "&.MuiCardHeader-root": { p: 0 },
          }}
        />
        <CardContent
          sx={{
            "&.MuiCardContent-root": { p: 0 },
          }}
        >
          {!items || !items.length ? (
            <Typography fontSize={"0.9rem"} color={"GrayText"}>
              No Packages
            </Typography>
          ) : <List>
            {
              items.map((item, index) => (
                <PackageListItem
                  key={index}
                  item={item}
                  title={`${!item.itemId ? "N/A" : item.itemId} - ${item.description}`}
                  index={index}
                  onEditTapped={(indexToEdit) =>
                    setDialogState({
                      isOpen: true,
                      action: "update",
                      indexToEdit,
                    })
                  }
                  onRemoveTapped={(index) => remove(index)}
                  isSpotMarket={false}
                />
              ))
            }
          </List>
          }
        </CardContent>
        <Controller
          name={`stackability`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              sx={{ width: "100%", margin: 0, py: 2 }}
              control={<Checkbox {...field} checked={!!field.value} />}
              label={"Stackability - checked if stackable items"}
            />
          )}
        />
      </Card>
      <PackageDialog
        isOpen={dialogState.isOpen}
        packageIndex={dialogState.indexToEdit}
        isEdditing={dialogState.action !== 'create'}
        onClose={() => {
          if (dialogState.action === "create") {
            remove(items.length);
          }
          setDialogState(InitialDialogState);
        }}
        onUpdated={(index) => {
          const item = getValues(`items.${index}`);
          update(index, item);
          setDialogState(InitialDialogState);
        }}
      />
    </Box>
  );
}