import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, ListItemText, Collapse } from "@mui/material";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useAuth } from "src/hooks/useAuth";
import { useState } from "react";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const { session } = useAuth();
  
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1, px: 0,  "& .MuiListItemButton-root": { height: 50}, width: 50 }}>
        {data
          .filter(item => item.showWhen ? item.showWhen(session) : true)
          .map(item => (
            <NavItem
              key={item.title}
              item={{
                ...item,
                children: item.children?.filter(child =>
                  child.showWhen ? child.showWhen(session) : true
                ), 
              }}
            />
          ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { session } = useAuth();  
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const hasChildren = Boolean(children);

  return (
    <>
      <StyledNavItem
        component={hasChildren ? "div" : RouterLink}
        to={!hasChildren ? path : undefined}
        sx={{
          fontWeight: "bold",
          letterSpacing: 0.25,          
          borderLeft: "2px solid white",
          "&.active, &.active&:hover": {
            borderLeft: "2px solid #637381",
            backgroundColor: "rgb(248 252 255)",
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
          "&.active, &:hover": {
            color: "#454F5B",
          },
          cursor: hasChildren ? "pointer" : "inherit",
        }}
        onClick={hasChildren ? handleToggle : undefined}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

        <ListItemText disableTypography primary={title} />        

        {info && info}
      </StyledNavItem>

      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 4 }}>
            {children.map((child) => (
              <NavItem 
                key={typeof child.title === "function" ? child.title(session) : child.title}
                item={{
                  ...child,
                  title: typeof child.title === "function" ? 
                          child.title(session) : 
                          child.title,
                }} 
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
