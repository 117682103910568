import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormInput } from "src/components/form/FormInput";
import { getDayjsDate } from "src/utils/formatTime";

export function BasicInfos() {
    const { control } = useFormContext();

    return (
        <Card
            variant="outlined"
            sx={{
                py: "1.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
            }}
        >
            <CardHeader
                title={"Basic Info"}
                sx={{ "&.MuiCardHeader-root": { p: 0 } }}
            />
            <CardContent
                sx={{
                    "&.MuiCardContent-root": { p: 0, py: "1rem" },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem",
                }}
            >
                <Grid container flex={1} columnGap={1}>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="PO_NUM" label={"PO Number"} fullWidth />
                    </Grid>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="RELEASE_NUM" type="number" label={"Release Number"} fullWidth />
                    </Grid>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="PO_LINE_NUM" type="number" label={"PO Line Number"} fullWidth />
                    </Grid>
                    <Grid itemxs={3} flex={1}>
                        <FormInput name="SHIPMENT_NUM" type="number" label={"Shipment Number"} fullWidth />
                    </Grid>
                </Grid>
                <FormInput name="VENDOR_NM" label={"Vendor Name"} fullWidth />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        gap: 0.5,
                    }}
                >
                    <Controller
                        control={control}
                        name={`PROMISED_DT_NBD`}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <DateTimePicker
                                {...field}
                                value={getDayjsDate(field.value)}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                                timezone="UTC"
                                inputRef={ref}
                                sx={{ width: "100%" }}
                                label="Promise DT NBD"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={`ACTUAL_NBD`}
                        rules={{ required: true }}
                        render={({ field: { ref, ...field } }) => (
                            <DateTimePicker
                                {...field}
                                value={getDayjsDate(field.value)}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                                timezone="UTC"
                                inputRef={ref}
                                sx={{ width: "100%" }}
                                label="Actual NBD"
                            />
                        )}
                    />
                </Box>              
            </CardContent>
        </Card>
    )
}