import config from "src/config.js";
import {
  BaseService
} from "./base.service";

const baseURL = `${config.apiUrl}/orders`;
const validatePoNumbers = `${baseURL}/validate`;
const getPoNumbers = `${baseURL}/po_numbers`;
const getOrderById = `${baseURL}/details`;

export class OrderService extends BaseService {
  async postValidatePoNumbers(datas, signal = null) {
    const body = [];
    if (Array.isArray(datas)) {
      datas?.forEach((data, i) =>
        body.push({
          param: `poNumber.${i}`,
          value: data.trim(),
        })
      );
    } else body.push(datas); 

    const response = await fetch(validatePoNumbers, this.header({
      method: "POST",
      body: JSON.stringify(body),
      signal, //for aborting request on unmount
    }));

    const data = await response.json();
    if (response.ok || data.errors) {
      return data;
    }

    throw new Error(data?.errors[0]?.msg || response.statusText);
  }

  async getPoNumbers() {
    const response = await fetch(getPoNumbers, this.header());
    const data = await response.json();

    if (response.ok) {
      return data;
    }

    throw new Error(data?.errors[0]?.msg || response.statusText);
  }

  async getOrdersById(orderId) {
    const uri = new URL(getOrderById);
    uri.searchParams.append('orderIds', orderId)
    const response = await fetch(uri.toString(), this.header());        
    const data = await response.json();

    if (response.ok) {
      return data;
    }

    throw new Error(data?.errors[0]?.msg || response.statusText);
  }

  async getOrders() {
    const response = await fetch(baseURL, this.header());
    const data = await response.json();

    if (response.ok) {
      return data;
    }

    throw new Error(data?.errors[0]?.msg || response.statusText);
  }

  create = async (data) => {
    const uri = new URL(baseURL+ '/create');

    const response = await fetch(
      uri.toString(),
      this.header({
        method: 'POST',
        body: JSON.stringify(data)
      })
    );

    const OrderResponseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(OrderResponseJson);
      throw new Error(`Error while creating order: \n ${errorMessage}`);
    }
    return OrderResponseJson;
  }    

  updateOrder = async (orderId, data) => {
    const uri = new URL(baseURL + `/${orderId}`);

    const response = await fetch(
      uri.toString(),
      this.header({
        body: JSON.stringify(data),
        method: "PUT"
      })
    );

    return await response.json();
  };

  getOrder = async (orderId) => {
    const uri = new URL(`${baseURL}/${orderId}`);

    const response = await fetch(uri.toString(), this.header());
    return await response.json();
  }
  
  async uploadOrdersFile (file, fileType) {
    const uri = new URL(`${baseURL}/upload`);
    uri.searchParams.append("context", fileType);

    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(uri.toString(), this.header({
      method: "POST",
      body: formData,
    }, true));

    const responseJson = await response.json();

    if (!response.ok) {
      const errorMessage = getErrorMessage(responseJson);
      throw new Error(`Error uploading ${fileType} file: ${errorMessage}`);
    }
    return responseJson;
  };
}

function getErrorMessage(error) {
  let errorMessage;

  if (error.message)
    errorMessage = error.message;
  if (error.msg)
    errorMessage = error.msg;
  if (error.messages) {
    if (Array.isArray(error.messages)) {
      errorMessage = error.errors.join(', ');
    }
  }
  if (error.errors && Array.isArray(error.errors)) {
    errorMessage = error.errors.map(error => error.msg).join(', ');
  }

  return errorMessage;
}