import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';

import {
  RadioButtonCheckedOutlined
} from "@mui/icons-material";
import { formatDateString } from "src/utils/formatTime";

export function ShipmentStatusLogHistory({ statusLog = [] }) {
  const LAST_ELEMENT_INDEX = statusLog?.length - 1;

  const renderDivider = () => (
    <div style={{ width: 1, height: '100%', backgroundColor: '#CECECE' }} />
  );

  const renderItem = (item, index) => {
    const formattedDate = formatDateString(new Date(item.timestamp).toLocaleString('en-US'));

    return (
      <Grid key={index} container sx={{ paddingBottom: '0.5rem' }}>

        <Grid sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', paddingRight: 4
        }} >
          <RadioButtonCheckedOutlined fontSize={"0.4rem"} color="primary" sx={{ marginBottom: 1, paddingTop: '0.2rem' }} />
          {index !== LAST_ELEMENT_INDEX && renderDivider()}
        </Grid>

        <Grid>
          <Typography sx={{ color: "text.secondary" }} fontSize={"0.9rem"}>{formattedDate}</Typography>

          <Box sx={{ display: 'flex', gap: '2px' }}>
            <Typography fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>Status</Typography>
            <Typography fontWeight="600" fontSize={"0.9rem"} sx={{ whiteSpace: 'pre-line' }}>{item.name}</Typography>
          </Box>
        </Grid>
      </Grid>
    )
  };

  const renderTrackingStatus = () => (
    statusLog?.map((item, index) => (
      renderItem(item, index)
    ))
  );

  const renderEmptyList = () => (
    <Typography variant="body1" sx={{ color: "text.secondary" }}>
      No shipment updates yet.
    </Typography>
  );

  return (
    <Paper
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      elevation={0}
    >
      <Typography 
        sx={{ py: 2 }} 
        fontWeight={800} 
        fontSize={'1rem'} 
      >Last Updates</Typography>
      {statusLog?.length ? renderTrackingStatus() : renderEmptyList()}
    </Paper>
  );
}
