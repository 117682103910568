import { useNavigate, useParams } from "react-router-dom";
import { useOrderQueries } from "./hooks/useOrderQueries";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { OrderColumns, OrderDetailColumns } from "./components/OrderColumns";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { useAuth } from "src/hooks/useAuth";
import OrderDetailsDrawer from "./components/OrderDetailsDrawer";
import { AddOutlined, FileDownload } from "@mui/icons-material";
import { UploadInput } from "./components/UploadOrdersInput";
import { DataTable } from "src/components/datagrid/DataTablePaginated";

export function OrdersPage() {
  const { orderId } = useParams();
  const { session } = useAuth();
  const navigate = useNavigate();

  const {
    isOrdersLoading,
    orders: ordersData,
    getOrders,
    getTemplate,
  } = useOrderQueries();

  const [selectedItemsIds, setSelectedItemsIds] = useState([]);

  const toggleCreateShipmentModalVisibility = async () => {
    return `/shipments/create?orders=${selectedItemsIds ? selectedItemsIds.join(',') : ''}`;
  };

  const [shipmentUrl, setShipmentUrl] = useState('');
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const generateUrl = async () => {
      const url = await toggleCreateShipmentModalVisibility();
      setShipmentUrl(url);
    };

    if (selectedItemsIds.length > 1) {
      generateUrl();
    }
  }, [selectedItemsIds]);

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (ordersData)
      setOrders(ordersData);
  }, [ordersData]);

  const handleUploadedOrders = (data) => {
    const updatedOrders = [...orders];
  
    data.forEach((newOrder) => {
      const existingOrderIndex = updatedOrders.findIndex(
        (order) => order.PO_NUM === newOrder.PO_NUM
      );
  
      if (existingOrderIndex !== -1) {
        updatedOrders[existingOrderIndex] = {
          ...updatedOrders[existingOrderIndex],
          ...newOrder,
        };
      } else {
        updatedOrders.push(newOrder);
      }
    });

    setOrders(updatedOrders);
  };


  const CustomToolbarActions = () => {
    return <>
      {selectedItemsIds && selectedItemsIds.length > 1 && (
        <Button
          href={shipmentUrl}
          sx={{ maxHeight: "fit-content" }}
        >
          <AddOutlined sx={{ width: '1.2rem', height: '1.2rem', mr: '0.5rem' }} />
          <Typography sx={{ fontSize: '0.8rem', fontWeight: '800'}}>Create Shipment Selection</Typography>
        </Button>
      )}
    </>;
  }

  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <Container
        maxWidth="false"
        sx={{ width: "fit-content", maxWidth: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 1,
              textAlign: "center",
              display: "flex",
            }}
          >
            Orders
          </Typography>
        </Box>
        { session?.actions?.['UploadOrders'] && 
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate("/orders/create")}
            sx={{ mb: 1, textTransform: "capitalize" }}
          >
            + New Order
          </Button>
          
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                size="small"
                variant="outlined"
                onClick={ getTemplate }
                sx={{ mb: 1, textTransform: "capitalize" }}
              >
                <FileDownload />
                Download Template
              </Button>
              <UploadInput
                fileType={"Orders"}
                uploadCallback={ handleUploadedOrders }
              />
            </Box>
        </Box> }
        <Grid container item xs={12} justifyContent={"center"}>
          <Card>
            <section className="dataTable">
              <DataTable
                selectedItemId={orderId}
                loading={isOrdersLoading}
                loadData={orders ?? []}
                columns={OrderColumns(session)}
                className="datagrid"
                customDrawerComponent={OrderDetailsDrawer}
                pagination
                autoHeight
                density="compact"
                disableDensitySelector
                initState={{
                  columns: {
                    columnVisibilityModel: { id: false },
                  },
                }}
                slots={{
                  toolbar: () => <CustomToolbar
                    columns={OrderColumns(session)}
                    rows={orders}
                    title="Orders"
                    trailing={CustomToolbarActions}
                  />,
                  loadingOverlay: LinearProgress,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    quickFilterProps: { debounceMs: 500 },
                    loadData: orders ?? [],
                  },
                }}
                detailsDrawerTitle="Orders"
                detailColumns={OrderDetailColumns(session)}
                modelKey={"orders"}
                modelGridSetup={{}}
                disableMultipleRowSelection={true}
                onRowSelectionModelChange={(selectedItemsIds, details) => {
                  setSelectedItemsIds(selectedItemsIds);                  
                }}
                onEditTapped={() => navigate(`./edit`)}
              />
            </section>
          </Card>
        </Grid>
      </Container>
    </>
  );
}
