export const status = [
  {
    label: "Must go today",
    key: "must_go_today",
    color: "#FFFFFF",
    backgroundColor: "#f75d5d",
    display: true,
  },
  {
    label: "Aging load",
    key: "aging_load",
    color: "#ff0000",
    backgroundColor: "#E3F2FD",
    display: true,
  },
  {
    label: "Today only",
    key: "today_only",
    color: "#333333",
    backgroundColor: "#D0EAFD",
    display: true,
  },
  {
    label: "Today & Tomorrow",
    key: "today_and_tomorrow",
    fontWeight: "bold",
    color: "#333333",
    backgroundColor: "#CCE7F3",
    display: true,
  },
  {
    label: "Quotations Only",
    key: "quotations_only",
    color: "#107C41",
    backgroundColor: "#D4F4DD",
    display: true,
    fontWeight: "bold",
  },
  {
    label: "For Deletion",
    key: "for_deletion",
    color: "#721c24",
    backgroundColor: "#F8D7DA",
    display: true,
  },
  {
    label: "New Load",
    key: "new_load",
    color: "#333",
    backgroundColor: "#FFE066",
    fontWeight: "bold",
    display: false,
  },
  {
    label: "Updated Rate",
    key: "updated_rate",
    color: "#333",
    backgroundColor: "#FFE066",
    display: false,
  },
  {
    label: "Need to Post (DAT & TS)",
    key: "need_post",
    color: "#333",
    backgroundColor: "#FFE066",
    display: false,
  },
];
