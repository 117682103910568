import { Box, Button, Divider } from "@mui/material";
import { HazmatFieldsWrapper } from "../HazmatFields";

import { ItemsFieldsWrapper } from "../ItemsFields/ItemsFieldsWrapper";
import { PackageBasicInfoForm } from "./PackageBasicInfo";
import { useFormSteps } from "../../../../../components/form/FormStepper";
import { PackageSpecificationForm } from "./PackageSpecification";
import { PackageAddionalInfo } from "./PackageAddionalInfo";
import { useEffect } from "react";
import { usePackageForm } from "src/pages/shipments/context/PackageContext";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";
import { useFormContext } from "react-hook-form";
import { OrderUtils } from "src/utils";

export function PackageForm() {
  const { currentStepIndex } = useFormSteps();
  const { packageIndex } = usePackageForm();
  const { setValue, getValues } = useFormContext();
  const { getOrderByPONumber } = useShipmentFormContext();

  // TODO - HANDLE CHANGE OF PO NUMBER
  useEffect(() => {
    if (currentStepIndex === 0) {
      const items = getValues(`items.${packageIndex}.subItems`);
      const poNumbers = getValues(`items.${packageIndex}.poNumber`);

      if ((!items || !items.length) && poNumbers) {        
        poNumbers?.forEach((poNumber) => {
          const orders = getOrderByPONumber(poNumber);
          if (orders?.length) {
            orders.forEach((order,index) => {
              const item = {
                sku: order.ITEM_NUM,
                description: order.ITEM_DESC,
                quantityUOM: OrderUtils.toShipmentUOM(order.UOM),
              };
              setValue(`items.${packageIndex}.subItems.${index}`, item);
            })

          }
        });
      }
    }
  }, [currentStepIndex]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "32",
      }}
    >
      {currentStepIndex == 0 && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: '2rem' }}>
          <PackageBasicInfoForm
            isSupplierManager={true}
          />
          <PackageSpecificationForm />
          <PackageAddionalInfo />
          <HazmatFieldsWrapper />
        </Box>
      )}

      {currentStepIndex == 1 && (
        <ItemsFieldsWrapper />
      )}
    </Box>
  );
}

