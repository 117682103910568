import * as React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { StandardDialog } from 'src/components/dialog/Dialog';
import { OrderDetails } from './backoffice/InvalidOrderDetails';

function DialogSendToSpotMarket(props) {
    const [shouldSend, setShouldSend] = React.useState(false);

    return (
        <StandardDialog isOpen={props.open} onClose={props.onClose}>
            <Box sx={{ width: '100%' }}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <Box sx={{ pl: 2, pr: 2 }}>
                    {props.invalidOrders && (
                        <OrderDetails invalidOrders={props.invalidOrders} />
                    )}
                </Box>
                <DialogActions sx={{ pr: 6, pb: 4 }}>
                    <Button
                        sx={{ mr: 2 }}
                        variant="outlined"
                        onClick={() => {
                            props.handleShouldSendToSpotMarket(true)
                        }}
                    >
                        Yes, Confirm and Send to Spotmarket
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            props.handleShouldSendToSpotMarket(false)
                        }}
                    >
                        No, just Confirm
                    </Button>
                </DialogActions>
            </Box>
        </StandardDialog>
    );
}

export default DialogSendToSpotMarket;
